<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.95856 1.59998C7.46304 1.59998 7.27464 1.9966 7.313 2.76498C7.33846 3.2715 7.50906 4.68364 6.79416 5.39922C6.12742 6.0663 5.9335 6.00704 5.17378 6.86844C4.41438 7.72952 3.7814 7.5524 2.89528 7.47626C1.7821 7.3808 2.16134 13.561 2.9459 13.4539C3.5028 13.3777 4.76878 13.2258 4.97128 13.2258C5.17378 13.2258 6.5625 14.3021 7.78152 14.315C9.75598 14.3355 10.8449 13.9856 10.8449 13.9856L11.4236 12.6723L11.6172 8.66798L10.6927 6.74166C10.6927 6.74166 9.45222 6.94426 9.09782 6.38708C8.74344 5.82992 9.37642 5.14596 9.37642 3.55024C9.37642 1.95454 8.2117 1.59998 7.95856 1.59998Z"
      fill="url(#paint0_linear_818_76202)"
    />
    <path
      d="M10.9139 13.9738L10.8388 13.6997C10.6163 13.676 10.3301 13.7773 9.93916 13.7743C8.0721 13.7598 6.28726 13.2235 5.52142 12.6169C5.01976 12.2197 4.79976 11.6911 4.44538 11.843C4.00814 12.0306 2.76764 12.3222 2.31138 11.7445C2.43902 12.733 2.66668 13.4918 2.9459 13.4538C3.5028 13.3776 4.76878 13.2257 4.97128 13.2257C5.17378 13.2257 6.52384 14.2737 7.78152 14.3149C9.40864 14.368 10.8538 14.0877 10.8538 14.0877L10.9139 13.9738Z"
      fill="url(#paint1_linear_818_76202)"
    />
    <path
      d="M3.68596 7.86646C4.4202 8.06906 4.88658 7.73384 5.26184 7.27766C5.63464 6.82456 5.95802 6.4227 6.89478 5.81488C7.83152 5.20704 7.71124 3.53522 7.63516 2.97804C7.55906 2.42086 7.60968 1.59998 7.95856 1.59998C7.46302 1.59998 7.27464 1.9966 7.31298 2.76498C7.33846 3.27152 7.50904 4.68364 6.79414 5.39922C6.1274 6.0663 5.93348 6.00704 5.17378 6.86844C4.41438 7.72954 3.78138 7.5524 2.89526 7.47628C2.89558 7.66846 2.95172 7.66384 3.68596 7.86646Z"
      fill="url(#paint2_linear_818_76202)"
    />
    <path
      d="M9.09782 6.3871C8.74344 5.82992 9.37642 5.14596 9.37642 3.55024C9.37642 1.95454 8.212 1.59998 7.95856 1.59998C7.89198 1.59998 7.83276 1.60734 7.77968 1.62146C8.81248 1.68624 9.43442 2.98938 9.0785 4.33306C8.78976 5.42346 8.64586 6.29776 9.11746 6.81042C9.42244 7.14228 10.029 7.08578 10.693 6.74166C10.6927 6.74166 9.45222 6.94426 9.09782 6.3871Z"
      fill="url(#paint3_linear_818_76202)"
    />
    <path
      d="M9.16746 6.95569C8.55994 7.15831 7.02548 7.63505 5.86322 8.68955C2.91766 11.3618 7.94658 11.6967 8.9852 13.9822C9.18372 14.4191 10.3441 11.6912 10.3441 11.6912L10.7194 11.0594L9.63506 8.17043L9.16746 6.95569Z"
      fill="url(#paint4_linear_818_76202)"
    />
    <path
      d="M9.25674 7.18713C8.64922 7.38975 6.16728 8.98055 6.16728 9.76611C6.16728 12.0332 7.88614 10.9142 8.81522 13.5893C8.94378 13.9598 9.3982 14.2714 9.3982 14.2714C9.77468 13.9641 10.3445 11.6912 10.3445 11.6912L10.7197 11.0595L9.63538 8.17041L9.25674 7.18713Z"
      fill="url(#paint5_linear_818_76202)"
    />
    <path
      d="M10.659 14.1357L11.8218 12.8055L12.078 7.2488L9.16716 6.95532C8.49888 7.3151 8.42156 7.8072 8.66978 8.59738C8.77412 8.92984 8.34976 9.025 8.32246 9.99354C8.30344 10.6649 8.74282 10.668 8.74282 10.8675C8.74282 11.0671 8.52404 11.0493 8.45132 11.6273C8.34762 12.4543 9.03982 12.4703 9.03982 12.6471C9.03982 12.8239 8.65536 12.9289 8.679 13.6031C8.70906 14.458 7.12214 14.2852 6.04916 13.5073C5.60856 13.1877 5.2241 13.1549 4.92402 13.2273C4.94274 13.2264 4.9587 13.2258 4.97066 13.2258C5.17316 13.2258 6.53334 14.315 7.7809 14.315C9.23988 14.315 10.2487 14.2296 10.659 14.1357Z"
      fill="url(#paint6_linear_818_76202)"
    />
    <path
      d="M10.9265 7.3219C10.0278 7.3219 9.53412 8.20048 9.87592 8.87554C10.2177 9.5506 9.24294 10.4442 9.34418 10.8841C9.44544 11.3241 9.62278 12.4375 9.76178 12.6217C9.90108 12.8059 9.5338 13.2237 9.5338 13.2237L10.6731 13.363L11.7362 12.894L11.6982 9.6777L11.812 7.66388L10.9265 7.3219Z"
      fill="url(#paint7_linear_818_76202)"
    />
    <path
      d="M5.1738 6.8685C4.68042 7.42782 4.24042 7.54908 3.7498 7.54448C3.89126 7.77288 3.98974 8.02246 3.97962 8.26652C3.95292 8.90628 4.09376 9.69492 4.20146 8.87466C4.28338 8.24994 4.7068 7.47786 5.21614 6.82092C5.20234 6.83658 5.18852 6.85192 5.1738 6.8685Z"
      fill="url(#paint8_linear_818_76202)"
    />
    <path
      d="M11.6074 6.71659C10.5448 6.67607 8.6345 6.61589 8.59184 7.72965C8.56116 8.53731 9.1337 8.80225 10.0192 8.83601C10.9047 8.86979 11.3475 8.88667 11.7141 8.90049C12.0808 8.91429 13.2345 8.90755 13.2458 7.93225C13.26 6.71383 11.6074 6.71659 11.6074 6.71659Z"
      fill="url(#paint9_linear_818_76202)"
    />
    <path
      d="M9.85968 10.7427C9.57494 10.7344 8.729 10.9477 8.64984 11.4853C8.58756 11.908 8.80326 12.4541 9.53934 12.583C10.4718 12.7463 12.0256 12.832 12.6061 12.6147C12.8654 12.5176 13.0915 12.246 13.1035 11.8343C13.1154 11.4226 12.8098 10.8679 12.1455 10.8486C11.4813 10.8295 11.1352 10.8397 10.7565 10.7948C10.3012 10.7414 10.0873 10.7494 9.85968 10.7427Z"
      fill="url(#paint10_linear_818_76202)"
    />
    <path
      d="M9.56298 12.6213C9.24018 12.6213 8.8413 12.9062 8.8413 13.3811C8.8413 13.856 8.96466 14.2075 9.65778 14.3405C10.3509 14.4734 11.4616 14.3595 11.8792 14.2266C12.2048 14.1228 12.3254 13.8183 12.3254 13.3148C12.3254 12.8114 12.0124 12.7233 11.6706 12.7233C11.3288 12.7233 10.9139 12.7856 10.6771 12.7733C10.0155 12.7383 9.88576 12.6213 9.56298 12.6213Z"
      fill="url(#paint11_linear_818_76202)"
    />
    <path
      d="M11.5169 6.94128C10.589 6.90596 8.92082 6.85224 8.88736 7.73268C8.86312 8.3712 9.36388 8.5824 10.1371 8.61188C10.9103 8.64134 12.9446 8.67848 12.9513 7.90766C12.9596 6.94434 11.5169 6.94128 11.5169 6.94128Z"
      fill="url(#paint12_linear_818_76202)"
    />
    <path
      d="M9.94528 10.9696C9.69736 10.9626 9.00332 11.0215 8.93674 11.5351C8.89162 11.8826 9.07174 12.329 9.7127 12.4355C10.5249 12.5706 11.8774 12.643 12.3827 12.4659C12.6082 12.3867 12.8049 12.1647 12.8144 11.8274C12.8243 11.49 12.5134 11.0765 11.9354 11.0596C11.3573 11.0427 11.0496 11.0964 10.7197 11.059C10.3233 11.0145 10.1435 10.9752 9.94528 10.9696Z"
      fill="url(#paint13_linear_818_76202)"
    />
    <path
      d="M9.70104 12.7946C9.42182 12.7946 9.07696 13.0239 9.07696 13.4064C9.07696 13.7889 9.18374 14.0719 9.78328 14.1787C10.3828 14.2856 11.3435 14.1941 11.7049 14.087C11.9863 14.0035 12.0909 13.7582 12.0909 13.3527C12.0909 12.9471 11.82 12.8765 11.5242 12.8765C11.2284 12.8765 10.8698 12.9269 10.6648 12.9167C10.0923 12.8888 9.97996 12.7946 9.70104 12.7946Z"
      fill="url(#paint14_linear_818_76202)"
    />
    <path
      d="M9.53076 7.73306C9.55378 7.12646 10.0373 6.83574 10.6482 6.7019C9.67312 6.72462 8.62316 6.90696 8.59156 7.72938C8.56088 8.53706 9.13342 8.80198 10.0189 8.83574C10.1165 8.83944 10.2082 8.84312 10.2954 8.84618C9.79832 8.70068 9.50652 8.3725 9.53076 7.73306Z"
      fill="url(#paint15_linear_818_76202)"
    />
    <path
      d="M9.6142 6.81177C9.04686 6.93579 8.61178 7.20409 8.59184 7.72935C8.56638 8.40073 8.9582 8.69697 9.59976 8.79735C8.29822 8.27763 8.7652 6.99749 9.6142 6.81177Z"
      fill="url(#paint16_linear_818_76202)"
    />
    <path
      d="M8.10338 13.1932C7.95334 13.5379 7.23934 13.5597 6.50848 13.2414C5.77762 12.9233 5.30694 12.3858 5.45698 12.0408C5.60702 11.696 6.321 11.6742 7.05188 11.9926C7.78244 12.3109 8.25342 12.8484 8.10338 13.1932Z"
      fill="url(#paint17_radial_818_76202)"
    />
    <path
      d="M12.4033 7.89598C12.3959 8.362 11.7731 8.58302 11.0916 8.62446C10.5344 8.65822 9.91584 8.26376 9.9318 7.8085C9.94776 7.35324 10.0288 6.94986 11.1867 6.99008C11.8691 7.01372 12.4104 7.44072 12.4033 7.89598Z"
      fill="url(#paint18_radial_818_76202)"
    />
    <path
      d="M12.5269 11.9924C12.523 12.6377 11.8139 12.514 11.1324 12.5588C10.2684 12.6159 9.69552 12.3467 9.69522 11.8911C9.6946 11.4355 10.0232 11.1037 11.1821 11.1018C11.8648 11.1009 12.5319 11.1635 12.5269 11.9924Z"
      fill="url(#paint19_radial_818_76202)"
    />
    <path
      d="M11.9023 13.6193C11.8989 14.1694 11.2545 14.2443 10.644 14.2446C10.0334 14.2452 9.37062 13.9214 9.37032 13.533C9.37 13.1447 9.66364 12.8617 10.6998 12.8601C11.3101 12.8592 11.9066 12.9126 11.9023 13.6193Z"
      fill="url(#paint20_radial_818_76202)"
    />
    <path
      d="M9.4421 11.616C9.53906 10.8814 10.1024 10.9244 10.7483 10.8482C10.0048 10.7927 9.69278 10.7847 9.69278 10.7847C9.62344 10.7779 9.5458 10.7776 9.46512 10.7859C9.22364 10.839 8.94994 10.9489 8.78732 11.1552C8.7321 11.2338 8.6873 11.3265 8.65784 11.4364C8.65478 11.4524 8.6517 11.4687 8.64986 11.4852C8.5974 11.9095 8.80328 12.4541 9.53936 12.583C9.64552 12.6017 9.75998 12.6192 9.87994 12.6355C9.93732 12.6429 9.99992 12.6506 10.0671 12.6592C10.0852 12.6613 10.1033 12.6635 10.1217 12.6656C9.71548 12.4737 9.3706 12.156 9.4421 11.616Z"
      fill="url(#paint21_linear_818_76202)"
    />
    <path
      d="M9.62186 10.7801C9.57184 10.7785 9.51876 10.7804 9.46446 10.7859C9.22084 10.8396 8.9447 10.951 8.783 11.1607C8.7296 11.2381 8.68664 11.3296 8.6578 11.4367C8.65474 11.4527 8.65258 11.4686 8.64982 11.4852C8.57526 11.903 8.78208 12.3954 9.42518 12.5584C8.32796 11.953 8.79618 10.7807 9.62186 10.7801Z"
      fill="url(#paint22_linear_818_76202)"
    />
    <path
      d="M10.2607 12.7334C9.93636 12.6859 9.8032 12.6211 9.56326 12.6211C9.24048 12.6211 8.8416 12.906 8.8416 13.3809C8.8416 13.5534 8.85818 13.7094 8.91186 13.8447C8.96648 13.9565 9.04596 14.0612 9.15488 14.1496C9.27454 14.2331 9.43716 14.2978 9.65776 14.3402C9.80596 14.3688 9.9735 14.3856 10.149 14.3939C9.81026 14.1972 9.2365 14.0912 9.35676 13.4325C9.48962 12.7049 9.92378 12.8031 10.2607 12.7334Z"
      fill="url(#paint23_linear_818_76202)"
    />
    <path
      d="M9.54884 12.6217C9.22882 12.63 8.8416 12.9133 8.8416 13.3812C8.8416 13.8162 8.94562 14.1477 9.49608 14.3024C8.63634 13.7499 8.91218 12.8176 9.54884 12.6217Z"
      fill="url(#paint24_linear_818_76202)"
    />
    <path
      d="M13.2459 7.93218C13.2471 7.8229 13.2348 7.72374 13.2118 7.63318C13.2974 8.01384 12.9584 8.39206 12.0597 8.57562C11.4641 8.6972 9.8824 8.84916 8.78794 8.41446C9.0242 8.69996 9.45898 8.81416 10.0192 8.83564C10.9048 8.86942 11.3475 8.8863 11.7142 8.90012C12.0808 8.91454 13.2342 8.90748 13.2459 7.93218Z"
      fill="url(#paint25_linear_818_76202)"
    />
    <path
      d="M13.1035 11.8084C13.1022 11.3995 12.7957 10.8675 12.1452 10.8488C11.481 10.8297 11.1349 10.8399 10.7562 10.795C10.3012 10.7413 10.0873 10.7493 9.85968 10.7429C9.68754 10.7379 9.2629 10.7898 8.97416 10.9694C9.2988 11.0821 9.82776 11.2515 10.5721 11.2039C12.3557 11.0891 12.8632 11.3049 13.1035 11.8084Z"
      fill="url(#paint26_linear_818_76202)"
    />
    <path
      d="M9.47708 8.90001C9.09754 8.89233 8.49462 9.11581 8.47898 9.90445C8.46332 10.6928 9.15276 10.8807 9.45652 10.8868C9.7603 10.893 10.8222 10.9654 12.1321 10.9915C13.4419 11.0176 13.6174 10.3143 13.6248 9.94589C13.6322 9.57721 13.3987 8.91075 12.6107 8.89479C10.3325 8.84905 9.47708 8.90001 9.47708 8.90001Z"
      fill="url(#paint27_linear_818_76202)"
    />
    <path
      d="M9.68078 9.12881C9.35064 9.12237 8.8272 9.29367 8.81522 9.90179C8.80296 10.5102 9.4028 10.6564 9.66668 10.6616C9.93086 10.6668 10.8541 10.7248 11.9927 10.7475C13.1314 10.7702 13.2827 10.2281 13.2882 9.94355C13.294 9.65927 13.2547 9.14477 12.5696 9.13095C10.5896 9.09167 9.68078 9.12881 9.68078 9.12881Z"
      fill="url(#paint28_linear_818_76202)"
    />
    <path
      d="M9.2936 9.82835C9.33164 9.01885 9.89652 9.01639 10.5341 8.87671C9.78852 8.88131 9.47708 8.89973 9.47708 8.89973C9.09754 8.89205 8.49462 9.11555 8.47898 9.90419C8.46332 10.6925 9.15276 10.8804 9.45652 10.8865C9.56606 10.8887 9.7744 10.8994 10.0567 10.9135C9.63572 10.7401 9.26568 10.4239 9.2936 9.82835Z"
      fill="url(#paint29_linear_818_76202)"
    />
    <path
      d="M12.9578 9.91223C12.9841 10.5569 12.2683 10.6782 11.5862 10.7107C10.9042 10.7432 10.146 10.3985 10.1242 9.94353C10.1024 9.48859 10.4151 9.14171 11.5724 9.08553C12.2545 9.05267 12.924 9.08399 12.9578 9.91223Z"
      fill="url(#paint30_radial_818_76202)"
    />
    <path
      d="M9.40616 8.90137C9.02692 8.92163 8.49366 9.16355 8.47892 9.90429C8.4639 10.6539 9.08614 10.8605 9.40862 10.8842C8.16074 10.3804 8.56976 8.97567 9.40616 8.90137Z"
      fill="url(#paint31_linear_818_76202)"
    />
    <path
      d="M13.6226 9.86621C13.4581 11.0327 10.3754 10.8191 8.70966 10.5686C8.93458 10.8096 9.26902 10.8826 9.45648 10.8866C9.76024 10.8928 10.8222 10.9652 12.132 10.9913C13.4419 11.0174 13.6174 10.3141 13.6248 9.94571C13.6251 9.92053 13.6244 9.89383 13.6226 9.86621Z"
      fill="url(#paint32_linear_818_76202)"
    />
    <path
      d="M12.0784 12.4639C11.4813 12.4903 9.9014 12.6868 8.74956 12.0694C8.87812 12.3083 9.12696 12.5112 9.53904 12.5833C10.4715 12.7466 12.0253 12.8323 12.6058 12.6149C12.8651 12.5179 13.0912 12.2463 13.1032 11.8346C13.0648 12.1925 12.7239 12.4354 12.0784 12.4639Z"
      fill="url(#paint33_linear_818_76202)"
    />
    <path
      d="M12.2796 13.7601C11.8884 14.0579 11.2122 14.2338 10.2365 14.069C9.63386 13.967 9.22086 13.9324 8.94932 13.925C9.05794 14.1215 9.2675 14.2657 9.65778 14.3406C10.3509 14.4736 11.4616 14.3597 11.8792 14.2267C12.0937 14.158 12.2189 14.002 12.2796 13.7601Z"
      fill="url(#paint34_linear_818_76202)"
    />
    <path
      d="M8.96958 12.9538C9.69246 12.6904 10.0469 13.0812 10.8204 13.0812C11.6237 13.0812 12.0066 12.7567 12.2919 13.0653C12.2023 12.7807 11.9458 12.7233 11.6706 12.7233C11.3288 12.7233 10.914 12.7856 10.6768 12.7733C10.0153 12.7386 9.88578 12.6213 9.56298 12.6213C9.35404 12.6213 9.11348 12.7408 8.96958 12.9538Z"
      fill="url(#paint35_linear_818_76202)"
    />
    <path
      d="M2.94624 13.4539C3.50312 13.3778 4.7691 13.2258 4.9716 13.2258C3.13554 11.9128 3.95202 9.1202 4.62918 7.70778C4.7243 7.50946 4.7829 7.34676 4.81452 7.20862C4.20362 7.67462 3.63844 7.54016 2.8953 7.4763C1.78212 7.38084 2.16166 13.561 2.94624 13.4539Z"
      fill="url(#paint36_linear_818_76202)"
    />
    <path
      d="M4.10386 12.1851C3.91394 11.9319 4.0784 12.4764 4.23058 12.8691C4.35976 13.2031 3.36902 13.0057 2.61084 13.0877C2.70994 13.3323 2.82316 13.4708 2.9462 13.4539C3.5031 13.3778 4.76908 13.2258 4.97158 13.2258C4.68592 12.983 4.29348 12.4384 4.10386 12.1851Z"
      fill="url(#paint37_linear_818_76202)"
    />
    <path
      d="M4.97126 13.2262C4.81386 13.092 4.62394 12.8661 4.45302 12.6478C4.45302 12.6478 3.53132 13.1196 2.54302 12.8977C2.65686 13.2587 2.7937 13.4748 2.94588 13.4539C3.50308 13.3781 4.76876 13.2262 4.97126 13.2262Z"
      fill="url(#paint38_linear_818_76202)"
    />
    <path
      d="M8.46276 3.97803C8.10192 4.05601 7.80952 3.55747 7.64444 2.97573C7.50944 2.49991 7.69292 1.89392 8.0473 1.82577C8.4017 1.75731 8.72878 1.75424 8.92422 2.76269C9.03898 3.35669 8.8156 3.90189 8.46276 3.97803Z"
      fill="url(#paint39_radial_818_76202)"
    />
    <path
      d="M9.54886 12.6216C9.35034 12.6425 9.17054 12.749 9.04688 12.8954C8.92138 13.0431 8.86278 13.2359 8.87568 13.4296C8.8769 13.6245 8.90636 13.8237 9.01682 13.9815C9.12636 14.1409 9.31044 14.2357 9.49608 14.3023C9.30462 14.259 9.10426 14.1798 8.976 14.0119C8.8459 13.8458 8.81584 13.6294 8.8094 13.4308C8.8008 13.2276 8.86554 13.0115 9.00884 12.8616C9.14782 12.7103 9.3485 12.6176 9.54886 12.6216Z"
      fill="#804B24"
    />
    <path
      d="M9.2304 10.8547C9.09968 10.9115 8.9791 10.9892 8.87938 11.0859C8.77782 11.1814 8.72382 11.3122 8.6867 11.4448L8.687 11.4426C8.63914 11.6845 8.67964 11.9421 8.8217 12.1432C8.96038 12.347 9.1856 12.4802 9.42492 12.5582C9.17854 12.5106 8.93032 12.3906 8.77444 12.1766C8.69744 12.0713 8.64404 11.9482 8.61826 11.8196C8.59218 11.691 8.60416 11.5565 8.62808 11.431L8.6287 11.4288C8.66766 11.2894 8.73702 11.1498 8.85146 11.0555C8.961 10.9582 9.09538 10.8983 9.2304 10.8547Z"
      fill="#804B24"
    />
    <path
      d="M9.40618 8.901C9.17146 8.9277 8.9444 9.02532 8.78272 9.18956C8.6204 9.3538 8.53264 9.58004 8.51854 9.81058C8.4949 10.0402 8.53204 10.28 8.66336 10.4706C8.79284 10.6618 9.00454 10.7902 9.23098 10.8565C8.99902 10.8141 8.76828 10.6996 8.62162 10.5004C8.4719 10.3027 8.43232 10.0439 8.45226 9.80506C8.4719 9.565 8.56608 9.32002 8.74712 9.15334C8.9263 8.9848 9.16962 8.90346 9.40618 8.901Z"
      fill="#804B24"
    />
    <path
      d="M9.61422 6.81177C9.36324 6.88053 9.11378 6.97539 8.91588 7.13841C8.71644 7.30049 8.60844 7.55069 8.62502 7.80701C8.62164 8.06151 8.70478 8.32121 8.89808 8.48975C9.08862 8.66043 9.34574 8.74333 9.5995 8.79735C9.34206 8.76697 9.0742 8.70249 8.86464 8.52813C8.65016 8.35621 8.55904 8.07133 8.55874 7.80763C8.54492 7.53963 8.66552 7.25905 8.88398 7.09881C9.09814 6.93457 9.35802 6.85721 9.61422 6.81177Z"
      fill="#804B24"
    />
    <path
      d="M8.74986 12.0691C8.9226 12.3684 9.25458 12.5234 9.58258 12.5676C9.91642 12.6192 10.2527 12.6545 10.5899 12.676C10.9265 12.7046 11.2643 12.7224 11.6021 12.7211C11.7709 12.719 11.9399 12.7147 12.1078 12.6981C12.2756 12.6846 12.445 12.6674 12.6064 12.6143C12.287 12.7343 11.9406 12.7534 11.6031 12.7653C11.2637 12.7739 10.924 12.7644 10.5853 12.7423C10.2472 12.7131 9.90998 12.6705 9.57552 12.6112C9.40738 12.5836 9.23986 12.5314 9.09258 12.4418C8.94592 12.3512 8.82564 12.2208 8.74986 12.0691Z"
      fill="#804B24"
    />
    <path
      d="M8.70966 10.5686C8.83914 10.6975 9.00576 10.7826 9.17972 10.8259C9.35278 10.8777 9.53474 10.8664 9.7176 10.8747L10.8096 10.9063L11.901 10.96C12.2631 10.9821 12.6316 10.987 12.9786 10.8682C12.6405 11.0125 12.2606 11.0242 11.9001 11.0042L10.8081 10.9726L9.71668 10.9186C9.53718 10.9063 9.34848 10.9149 9.17084 10.8569C8.99532 10.8019 8.83118 10.7052 8.70966 10.5686Z"
      fill="#804B24"
    />
    <path
      d="M12.6107 8.89473C12.3496 8.89535 12.0882 8.9027 11.8271 8.90026L11.0437 8.90179L10.2607 8.91255C9.9999 8.91407 9.73848 8.91561 9.4786 8.92605H9.47646C9.21964 8.92175 8.95362 9.00955 8.7686 9.19343C8.5799 9.37547 8.49092 9.64041 8.47896 9.90441C8.4811 9.77209 8.49522 9.63855 8.53572 9.51055C8.57684 9.38285 8.6471 9.26342 8.74252 9.16732C8.93276 8.97178 9.20706 8.87479 9.47768 8.87417H9.47554C9.99868 8.84255 10.5212 8.84224 11.0437 8.85052C11.5666 8.85636 12.0888 8.86619 12.6107 8.89473Z"
      fill="#804B24"
    />
    <defs>
      <linearGradient
        id="paint0_linear_818_76202"
        x1="10.7338"
        y1="7.85214"
        x2="6.92536"
        y2="8.00496"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_818_76202"
        x1="6.62308"
        y1="13.1331"
        x2="6.5465"
        y2="11.1304"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_818_76202"
        x1="6.13484"
        y1="5.59384"
        x2="9.00386"
        y2="9.6958"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_818_76202"
        x1="4.05864"
        y1="3.95954"
        x2="11.4067"
        y2="4.50988"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3118" stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#FF8900" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_818_76202"
        x1="3.9187"
        y1="10.4928"
        x2="13.568"
        y2="10.4928"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#FFA754" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_818_76202"
        x1="2.36282"
        y1="10.5073"
        x2="21.426"
        y2="11.1146"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_818_76202"
        x1="5.3918"
        y1="12.9984"
        x2="8.5444"
        y2="11.29"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_818_76202"
        x1="9.00322"
        y1="10.5729"
        x2="11.6321"
        y2="10.2276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#643800" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#643800" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_818_76202"
        x1="4.33762"
        y1="4.76916"
        x2="4.90782"
        y2="14.2106"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#FFA754" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_818_76202"
        x1="13.0657"
        y1="7.80125"
        x2="9.3353"
        y2="7.80125"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_818_76202"
        x1="13.0657"
        y1="11.7437"
        x2="9.33322"
        y2="11.7437"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_818_76202"
        x1="13.0657"
        y1="13.5105"
        x2="9.3353"
        y2="13.5105"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_818_76202"
        x1="8.8324"
        y1="7.8197"
        x2="10.7754"
        y2="7.77542"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_818_76202"
        x1="12.2303"
        y1="11.77"
        x2="10.2497"
        y2="11.77"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_818_76202"
        x1="12.2303"
        y1="13.5106"
        x2="10.2497"
        y2="13.5106"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_818_76202"
        x1="9.81258"
        y1="7.77398"
        x2="8.7558"
        y2="7.77398"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FBA23B" stop-opacity="0" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_818_76202"
        x1="9.62268"
        y1="7.80463"
        x2="8.40736"
        y2="7.80463"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#E89043" />
      </linearGradient>
      <radialGradient
        id="paint17_radial_818_76202"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.68064 12.3514) rotate(23.5251) scale(1.48362 0.688824)"
      >
        <stop stop-color="#FBE07A" offset="0" />
        <stop offset="1" stop-color="#FFD748" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_818_76202"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.1693 7.8105) rotate(2.02718) scale(1.26941 0.833154)"
      >
        <stop stop-color="#FBE07A" offset="0" />
        <stop offset="1" stop-color="#FFD748" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_818_76202"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.1079 11.9011) rotate(-0.0544055) scale(1.36327 0.734904)"
      >
        <stop stop-color="#FBE07A" offset="0" />
        <stop offset="1" stop-color="#FFD748" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint20_radial_818_76202"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.6363 13.5533) rotate(-0.0539601) scale(1.24818 0.781204)"
      >
        <stop stop-color="#FBE07A" offset="0" />
        <stop offset="1" stop-color="#FFD748" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint21_linear_818_76202"
        x1="9.93918"
        y1="11.7226"
        x2="8.40754"
        y2="11.7226"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FBA23B" stop-opacity="0" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_818_76202"
        x1="9.6136"
        y1="11.669"
        x2="8.78856"
        y2="11.669"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_818_76202"
        x1="9.8841"
        y1="13.5206"
        x2="8.0929"
        y2="13.1916"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FBA23B" stop-opacity="0" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_818_76202"
        x1="9.6989"
        y1="13.462"
        x2="8.94178"
        y2="13.462"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_818_76202"
        x1="10.9058"
        y1="7.56632"
        x2="11.3873"
        y2="9.8965"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_818_76202"
        x1="11.2715"
        y1="12.1701"
        x2="10.7462"
        y2="10.2748"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_818_76202"
        x1="13.0657"
        y1="9.93377"
        x2="9.33532"
        y2="9.93377"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_818_76202"
        x1="9.1848"
        y1="9.82975"
        x2="12.0424"
        y2="9.93413"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="0.7382" stop-color="#FFD748" />
        <stop offset="1" stop-color="#FFCB4B" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_818_76202"
        x1="10.2999"
        y1="9.89523"
        x2="8.165"
        y2="9.89523"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FBA23B" stop-opacity="0" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <radialGradient
        id="paint30_radial_818_76202"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.5395 9.89953) rotate(-2.73606) scale(1.3959 0.916218)"
      >
        <stop stop-color="#FBE07A" offset="0" />
        <stop offset="1" stop-color="#FFD748" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint31_linear_818_76202"
        x1="9.30754"
        y1="9.89275"
        x2="8.63346"
        y2="9.89275"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_818_76202"
        x1="11.0828"
        y1="9.68721"
        x2="11.4883"
        y2="12.2644"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_818_76202"
        x1="10.8018"
        y1="11.681"
        x2="11.8407"
        y2="13.9349"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_818_76202"
        x1="10.595"
        y1="13.6581"
        x2="10.747"
        y2="15.0829"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_818_76202"
        x1="10.6308"
        y1="13.4421"
        x2="10.6308"
        y2="12.4115"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_818_76202"
        x1="3.67282"
        y1="10.5968"
        x2="0.44271"
        y2="15.4775"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C86F34" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_818_76202"
        x1="3.45954"
        y1="11.6764"
        x2="3.9917"
        y2="14.0508"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint38_linear_818_76202"
        x1="3.78178"
        y1="12.1219"
        x2="3.70574"
        y2="15.0284"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" offset="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <radialGradient
        id="paint39_radial_818_76202"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.28906 2.89549) rotate(79.0714) scale(1.33846 0.785176)"
      >
        <stop stop-color="#FBE07A" offset="0" />
        <stop offset="1" stop-color="#FFD748" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'thumb-up'
}
</script>
