<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4951 7.00571C10.5371 4.89904 7.33706 5.18857 8.15992 1.5238C8.15992 1.5238 6.01134 2.36952 6.38849 4.9638C6.51801 5.85524 6.20563 6.5219 6.20563 6.5219C5.88944 6.58666 5.39801 5.95809 5.56944 4.98666C5.56944 4.98666 4.44182 5.82857 4.44182 6.91044C4.44182 7.99238 4.96372 9.13904 4.96372 9.13904C3.66468 8.85711 3.7942 7.10478 3.7942 7.10478C3.7942 7.10478 2.97134 8.20571 2.97134 10.2819C2.97134 11.8971 4.7923 14.4762 7.72946 14.4762C10.6666 14.4762 13.0247 12.1981 13.0247 10.1714C13.0247 9.28378 12.7237 8.13711 12.0723 7.51238C12.0723 7.51238 12.1599 9.28378 11.2837 9.19998C11.2837 9.19998 12.0075 6.88764 11.2495 5.82857C11.2495 5.82857 10.8837 6.97144 10.4951 7.00571Z"
      fill="url(#paint0_linear_818_76044)"
    />
    <path
      d="M10.4989 5.41727C11.3522 3.84394 9.23795 3.6268 9.23415 2.65918C9.23415 2.65918 8.86081 3.65727 9.75228 4.33918C10.4799 4.89156 10.4989 5.41727 10.4989 5.41727Z"
      fill="url(#paint1_linear_818_76044)"
    />
    <path
      d="M6.01133 2.40759C5.68752 2.72759 6.13324 4.0495 5.1199 4.5714C5.1199 4.5714 4.77704 3.11997 6.01133 2.40759Z"
      fill="url(#paint2_linear_818_76044)"
    />
    <path
      opacity="0.5"
      d="M3.63801 7.6342C3.3561 8.11807 2.97134 9.01327 2.97134 10.2819C2.97134 11.699 4.77706 14.3047 7.72946 14.3047C10.6133 14.3047 12.8533 12.0837 12.8533 10.1752C12.8533 9.48567 12.6628 8.60567 12.2399 7.98087C12.2095 8.37327 12.1142 8.9066 11.8209 9.18474C11.6685 9.32947 11.478 9.3942 11.2685 9.3714L11.0551 9.35234L11.1199 9.1504C11.1275 9.1314 11.6799 7.32567 11.2799 6.25518C11.1199 6.64756 10.8457 7.1542 10.5104 7.18087L10.3199 7.19614L10.3237 7.00567C10.339 6.21708 9.85512 5.79804 9.29512 5.31423C8.49512 4.6209 7.59232 3.83614 7.92372 1.84375C7.36752 2.17137 6.28563 3.07423 6.5561 4.93708C6.69326 5.87042 6.36944 6.56375 6.35801 6.59423L6.31992 6.6742L6.2361 6.68947C6.0761 6.71994 5.90848 6.65518 5.7561 6.51042C5.55039 6.30852 5.36372 5.92756 5.3561 5.40947C5.04372 5.72947 4.61325 6.28185 4.61325 6.9104C4.61325 7.9428 5.1161 9.0552 5.11992 9.0666L5.26087 9.379L4.92563 9.3066C4.04944 9.11614 3.72182 8.30087 3.63801 7.6342Z"
      fill="url(#paint3_linear_818_76044)"
    />
    <path
      d="M9.23421 2.65918C9.15801 3.23442 9.38661 4.06109 9.75227 4.33918C10.0875 4.59442 10.4647 5.03251 10.4951 5.41727C11.0666 4.29727 10.2627 3.88204 9.80181 3.71823C9.60754 3.64965 9.24941 3.24965 9.23421 2.65918Z"
      fill="url(#paint4_radial_818_76044)"
    />
    <path
      d="M7.47806 2.49548C6.21333 4.47644 7.85526 6.56787 7.00953 7.82118C6.16381 9.07451 5.30666 7.91644 5.24952 7.13164C5.24952 7.13164 5.25714 9.66884 6.51048 10.6707C7.7638 11.6726 4.09904 11.4555 3.59619 10.2326C3.59619 10.2326 3.59619 14.305 7.72953 14.305C11.8629 14.305 12.5524 10.5145 12.5524 10.5145C12.5524 10.5145 11.0476 11.7983 10.0457 10.7964C10.0457 10.7964 10.7962 9.48218 10.9867 8.13358C10.9867 8.13358 10.4686 8.46118 9.6724 8.33551C9.6724 8.33551 10.1143 6.44215 9.2114 5.8212C8.05333 5.02501 7.07426 3.12787 7.47806 2.49548Z"
      fill="url(#paint5_linear_818_76044)"
    />
    <path
      d="M7.79427 4.82654C7.1314 5.69892 8.42287 7.9389 7.06667 8.8265C6.31618 9.31797 5.88571 8.7275 5.88571 8.7275C5.88571 8.7275 6.08761 9.63417 6.8952 10.4418C7.70287 11.2494 5.67999 12.3427 4.03809 10.9294C4.03809 10.9294 4.60189 14.3237 8.42287 13.7522C12.64 13.1237 12.3276 9.98844 12.3276 9.98844C12.3276 9.98844 11.1847 11.7865 9.55807 10.9256C9.55807 10.9256 10.5829 9.49704 10.5829 8.67797C10.5829 8.67797 9.88953 9.08937 9.09713 8.69317C9.09713 8.69317 9.47427 6.78844 9.02473 6.50272C7.94667 5.8132 7.79427 4.82654 7.79427 4.82654Z"
      fill="url(#paint6_linear_818_76044)"
    />
    <path
      opacity="0.5"
      d="M10.4951 7.00571C10.5371 4.89904 7.33706 5.18857 8.15992 1.5238C8.15992 1.5238 6.01134 2.36952 6.38849 4.9638C6.51801 5.85524 6.20563 6.5219 6.20563 6.5219C5.88944 6.58666 5.39801 5.95809 5.56944 4.98666C5.56944 4.98666 4.44182 5.82857 4.44182 6.91044C4.44182 7.99238 4.96372 9.13904 4.96372 9.13904C3.66468 8.85711 3.7942 7.10478 3.7942 7.10478C3.7942 7.10478 2.97134 8.20571 2.97134 10.2819C2.97134 11.8971 4.7923 14.4762 7.72946 14.4762C10.6666 14.4762 13.0247 12.1981 13.0247 10.1714C13.0247 9.28378 12.7237 8.13711 12.0723 7.51238C12.0723 7.51238 12.1599 9.28378 11.2837 9.19998C11.2837 9.19998 12.0075 6.88764 11.2495 5.82857C11.2495 5.82857 10.8837 6.97144 10.4951 7.00571Z"
      fill="url(#paint7_radial_818_76044)"
    />
    <path
      opacity="0.5"
      d="M10.4951 7.00571C10.5371 4.89904 7.33706 5.18857 8.15992 1.5238C8.15992 1.5238 6.01134 2.36952 6.38849 4.9638C6.51801 5.85524 6.20563 6.5219 6.20563 6.5219C5.88944 6.58666 5.39801 5.95809 5.56944 4.98666C5.56944 4.98666 4.44182 5.82857 4.44182 6.91044C4.44182 7.99238 4.96372 9.13904 4.96372 9.13904C3.66468 8.85711 3.7942 7.10478 3.7942 7.10478C3.7942 7.10478 2.97134 8.20571 2.97134 10.2819C2.97134 11.8971 4.7923 14.4762 7.72946 14.4762C10.6666 14.4762 13.0247 12.1981 13.0247 10.1714C13.0247 9.28378 12.7237 8.13711 12.0723 7.51238C12.0723 7.51238 12.1599 9.28378 11.2837 9.19998C11.2837 9.19998 12.0075 6.88764 11.2495 5.82857C11.2495 5.82857 10.8837 6.97144 10.4951 7.00571Z"
      fill="url(#paint8_radial_818_76044)"
    />
    <path
      d="M3.23047 9.39808C3.31809 11.5504 5.08952 14.1295 7.85907 14.1295C10.6285 14.1295 12.0114 11.2076 12.0991 9.49708C12.0991 9.49708 11.5809 10.5028 10.4114 10.7047C9.24187 10.9066 10.4914 8.69328 9.6724 7.38281C9.6724 7.38281 8.94854 9.66475 7.92 9.74855C6.8914 9.83235 6.01904 8.23995 6.01904 7.38281C6.01904 7.38281 5.38666 8.92568 5.54285 10.1523C5.78285 12.0152 3.23047 9.39808 3.23047 9.39808Z"
      fill="url(#paint9_radial_818_76044)"
    />
    <path
      opacity="0.5"
      d="M10.4951 7.00571C10.5371 4.89904 7.33706 5.18857 8.15992 1.5238C8.15992 1.5238 6.01134 2.36952 6.38849 4.9638C6.51801 5.85524 6.20563 6.5219 6.20563 6.5219C5.88944 6.58666 5.39801 5.95809 5.56944 4.98666C5.56944 4.98666 4.44182 5.82857 4.44182 6.91044C4.44182 7.99238 4.96372 9.13904 4.96372 9.13904C3.66468 8.85711 3.7942 7.10478 3.7942 7.10478C3.7942 7.10478 2.97134 8.20571 2.97134 10.2819C2.97134 11.8971 4.7923 14.4762 7.72946 14.4762C10.6666 14.4762 13.0247 12.1981 13.0247 10.1714C13.0247 9.28378 12.7237 8.13711 12.0723 7.51238C12.0723 7.51238 12.1599 9.28378 11.2837 9.19998C11.2837 9.19998 12.0075 6.88764 11.2495 5.82857C11.2495 5.82857 10.8837 6.97144 10.4951 7.00571Z"
      fill="url(#paint10_radial_818_76044)"
    />
    <path
      opacity="0.5"
      d="M10.4989 5.41727C11.3522 3.84394 9.23795 3.6268 9.23415 2.65918C9.23415 2.65918 8.86081 3.65727 9.75228 4.33918C10.4799 4.89156 10.4989 5.41727 10.4989 5.41727Z"
      fill="url(#paint11_radial_818_76044)"
    />
    <path
      d="M6.01133 2.40759C5.69133 2.72759 5.89704 3.73711 5.1199 4.5714C5.1199 4.5714 4.77704 3.11997 6.01133 2.40759Z"
      fill="url(#paint12_radial_818_76044)"
    />
    <path
      opacity="0.5"
      d="M6.01133 2.40759C5.68752 2.72759 6.13324 4.0495 5.1199 4.5714C5.1199 4.5714 4.77704 3.11997 6.01133 2.40759Z"
      fill="url(#paint13_radial_818_76044)"
    />
    <path
      opacity="0.5"
      d="M10.4951 7.00571C10.5371 4.89904 7.33706 5.18857 8.15992 1.5238C8.15992 1.5238 6.01134 2.36952 6.38849 4.9638C6.51801 5.85524 6.20563 6.5219 6.20563 6.5219C5.88944 6.58666 5.39801 5.95809 5.56944 4.98666C5.56944 4.98666 4.44182 5.82857 4.44182 6.91044C4.44182 7.99238 4.96372 9.13904 4.96372 9.13904C3.66468 8.85711 3.7942 7.10478 3.7942 7.10478C3.7942 7.10478 2.97134 8.20571 2.97134 10.2819C2.97134 11.8971 4.7923 14.4762 7.72946 14.4762C10.6666 14.4762 13.0247 12.1981 13.0247 10.1714C13.0247 9.28378 12.7237 8.13711 12.0723 7.51238C12.0723 7.51238 12.1599 9.28378 11.2837 9.19998C11.2837 9.19998 12.0075 6.88764 11.2495 5.82857C11.2495 5.82857 10.8837 6.97144 10.4951 7.00571Z"
      fill="url(#paint14_radial_818_76044)"
    />
    <path
      opacity="0.25"
      d="M10.4951 7.00571C10.5371 4.89904 7.33706 5.18857 8.15992 1.5238C8.15992 1.5238 6.01134 2.36952 6.38849 4.9638C6.51801 5.85524 6.20563 6.5219 6.20563 6.5219C5.88944 6.58666 5.39801 5.95809 5.56944 4.98666C5.56944 4.98666 4.44182 5.82857 4.44182 6.91044C4.44182 7.99238 4.96372 9.13904 4.96372 9.13904C3.66468 8.85711 3.7942 7.10478 3.7942 7.10478C3.7942 7.10478 2.97134 8.20571 2.97134 10.2819C2.97134 11.8971 4.7923 14.4762 7.72946 14.4762C10.6666 14.4762 13.0247 12.1981 13.0247 10.1714C13.0247 9.28378 12.7237 8.13711 12.0723 7.51238C12.0723 7.51238 12.1599 9.28378 11.2837 9.19998C11.2837 9.19998 12.0075 6.88764 11.2495 5.82857C11.2495 5.82857 10.8837 6.97144 10.4951 7.00571Z"
      fill="url(#paint15_radial_818_76044)"
    />
    <path
      opacity="0.25"
      d="M10.4951 7.00571C10.5371 4.89904 7.33706 5.18857 8.15992 1.5238C8.15992 1.5238 6.01134 2.36952 6.38849 4.9638C6.51801 5.85524 6.20563 6.5219 6.20563 6.5219C5.88944 6.58666 5.39801 5.95809 5.56944 4.98666C5.56944 4.98666 4.44182 5.82857 4.44182 6.91044C4.44182 7.99238 4.96372 9.13904 4.96372 9.13904C3.66468 8.85711 3.7942 7.10478 3.7942 7.10478C3.7942 7.10478 2.97134 8.20571 2.97134 10.2819C2.97134 11.8971 4.7923 14.4762 7.72946 14.4762C10.6666 14.4762 13.0247 12.1981 13.0247 10.1714C13.0247 9.28378 12.7237 8.13711 12.0723 7.51238C12.0723 7.51238 12.1599 9.28378 11.2837 9.19998C11.2837 9.19998 12.0075 6.88764 11.2495 5.82857C11.2495 5.82857 10.8837 6.97144 10.4951 7.00571Z"
      fill="url(#paint16_radial_818_76044)"
    />
    <path
      opacity="0.25"
      d="M10.4951 7.00571C10.5371 4.89904 7.33706 5.18857 8.15992 1.5238C8.15992 1.5238 6.01134 2.36952 6.38849 4.9638C6.51801 5.85524 6.20563 6.5219 6.20563 6.5219C5.88944 6.58666 5.39801 5.95809 5.56944 4.98666C5.56944 4.98666 4.44182 5.82857 4.44182 6.91044C4.44182 7.99238 4.96372 9.13904 4.96372 9.13904C3.66468 8.85711 3.7942 7.10478 3.7942 7.10478C3.7942 7.10478 2.97134 8.20571 2.97134 10.2819C2.97134 11.8971 4.7923 14.4762 7.72946 14.4762C10.6666 14.4762 13.0247 12.1981 13.0247 10.1714C13.0247 9.28378 12.7237 8.13711 12.0723 7.51238C12.0723 7.51238 12.1599 9.28378 11.2837 9.19998C11.2837 9.19998 12.0075 6.88764 11.2495 5.82857C11.2495 5.82857 10.8837 6.97144 10.4951 7.00571Z"
      fill="url(#paint17_radial_818_76044)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_818_76044"
        x1="8.64226"
        y1="14.8342"
        x2="6.30693"
        y2="-2.08968"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0000" />
        <stop offset="0.2317" stop-color="#FF1500" />
        <stop offset="0.7367" stop-color="#FF3D00" />
        <stop offset="0.9987" stop-color="#FF4C00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_818_76044"
        x1="11.3787"
        y1="14.4567"
        x2="9.04335"
        y2="-2.46715"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0000" />
        <stop offset="0.2317" stop-color="#FF1500" />
        <stop offset="0.7367" stop-color="#FF3D00" />
        <stop offset="0.9987" stop-color="#FF4C00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_818_76044"
        x1="7.15646"
        y1="15.0392"
        x2="4.82113"
        y2="-1.88469"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF0000" />
        <stop offset="0.2317" stop-color="#FF1500" />
        <stop offset="0.7367" stop-color="#FF3D00" />
        <stop offset="0.9987" stop-color="#FF4C00" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_818_76044"
        x1="8.57932"
        y1="11.9513"
        x2="3.4762"
        y2="-2.6228"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF8000" />
        <stop offset="0.6388" stop-color="#FF5B00" />
        <stop offset="0.9987" stop-color="#FF4C00" />
      </linearGradient>
      <radialGradient
        id="paint4_radial_818_76044"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.7649 0.0905782) scale(6.8004 6.8004)"
      >
        <stop offset="0.00134333" stop-color="#FFED1C" stop-opacity="0.6" />
        <stop offset="0.9477" stop-color="#FFED1C" stop-opacity="0.0264782" />
        <stop offset="0.9914" stop-color="#FFED1C" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint5_linear_818_76044"
        x1="6.59779"
        y1="2.22619"
        x2="8.443"
        y2="12.5766"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF8000" />
        <stop offset="0.022669" stop-color="#FE7F02" stop-opacity="0.9773" />
        <stop offset="0.6663" stop-color="#ED582A" stop-opacity="0.3337" />
        <stop offset="1" stop-color="#E74939" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_818_76044"
        x1="8.03067"
        y1="13.4104"
        x2="8.42947"
        y2="6.14292"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" />
        <stop offset="0.1869" stop-color="#FFB137" stop-opacity="0.8131" />
        <stop offset="0.7223" stop-color="#FF940F" stop-opacity="0.2777" />
        <stop offset="1" stop-color="#FF8900" stop-opacity="0" />
      </linearGradient>
      <radialGradient
        id="paint7_radial_818_76044"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.93146 11.4493) scale(5.26049 4.27046)"
      >
        <stop offset="0.00134333" stop-color="#FFBC47" />
        <stop offset="0.2941" stop-color="#FFBF4B" stop-opacity="0.7068" />
        <stop offset="0.5624" stop-color="#FEC656" stop-opacity="0.4382" />
        <stop offset="0.8201" stop-color="#FCD368" stop-opacity="0.1801" />
        <stop offset="1" stop-color="#FBE07A" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint8_radial_818_76044"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.93146 11.233) scale(5.09839 4.13887)"
      >
        <stop offset="0.00134333" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint9_radial_818_76044"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.69596 12.1734) scale(6.26301 7.292)"
      >
        <stop offset="0.00134333" stop-color="#FFED1C" stop-opacity="0.6" />
        <stop offset="0.9477" stop-color="#FFED1C" stop-opacity="0.0264782" />
        <stop offset="0.9914" stop-color="#FFED1C" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_818_76044"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.93172 11.2325) rotate(92.8876) scale(1.97941 1.6068)"
      >
        <stop offset="0.00134333" stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint11_radial_818_76044"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.02581 2.65557) scale(2.24679)"
      >
        <stop offset="0.00134333" stop-color="#FFED1C" />
        <stop offset="0.1997" stop-color="#FFEE2B" stop-opacity="0.8013" />
        <stop offset="0.5839" stop-color="#FFF254" stop-opacity="0.4167" />
        <stop offset="1" stop-color="#FFF686" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint12_radial_818_76044"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.32103 3.73655) rotate(-56.4116) scale(1.81995 0.413317)"
      >
        <stop offset="0.00134333" stop-color="#FFED1C" stop-opacity="0.6" />
        <stop offset="0.9477" stop-color="#FFED1C" stop-opacity="0.0264782" />
        <stop offset="0.9914" stop-color="#FFED1C" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint13_radial_818_76044"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.68145 2.30941) rotate(95.0573) scale(1.63531 0.658001)"
      >
        <stop offset="0.00134333" stop-color="#FFED1C" />
        <stop offset="0.1997" stop-color="#FFEE2B" stop-opacity="0.8013" />
        <stop offset="0.5839" stop-color="#FFF254" stop-opacity="0.4167" />
        <stop offset="1" stop-color="#FFF686" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_818_76044"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.52666 2.36712) scale(7.01067)"
      >
        <stop offset="0.00134333" stop-color="#FF4C00" />
        <stop offset="0.135" stop-color="#FF5B0C" stop-opacity="0.8661" />
        <stop offset="0.3941" stop-color="#FF842C" stop-opacity="0.6067" />
        <stop offset="0.7491" stop-color="#FFC45F" stop-opacity="0.2512" />
        <stop offset="1" stop-color="#FFF686" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint15_radial_818_76044"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.7153 8.04684) scale(3.72115)"
      >
        <stop offset="0.00134333" stop-color="#FFED1C" />
        <stop offset="0.1997" stop-color="#FFEE2B" stop-opacity="0.8013" />
        <stop offset="0.5839" stop-color="#FFF254" stop-opacity="0.4167" />
        <stop offset="1" stop-color="#FFF686" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint16_radial_818_76044"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.09799 9.37311) scale(3.96627)"
      >
        <stop offset="0.00134333" stop-color="#FFED1C" />
        <stop offset="0.1997" stop-color="#FFEE2B" stop-opacity="0.8013" />
        <stop offset="0.5839" stop-color="#FFF254" stop-opacity="0.4167" />
        <stop offset="1" stop-color="#FFF686" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint17_radial_818_76044"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.87226 8.57144) rotate(86.1102) scale(6.79393 4.13001)"
      >
        <stop stop-color="#FF4C00" stop-opacity="0" />
        <stop offset="0.9874" stop-color="#FF4C00" stop-opacity="0.9888" />
        <stop offset="0.9987" stop-color="#FF4C00" />
      </radialGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'fire'
}
</script>
