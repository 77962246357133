<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.095 14.19C11.4612 14.19 14.19 11.4612 14.19 8.095C14.19 4.72882 11.4612 2 8.095 2C4.72882 2 2 4.72882 2 8.095C2 11.4612 4.72882 14.19 8.095 14.19Z"
      fill="url(#paint0_radial_818_76075)"
    />
    <path
      opacity="0.5"
      d="M8.095 14.19C11.4612 14.19 14.19 11.4612 14.19 8.095C14.19 4.72882 11.4612 2 8.095 2C4.72882 2 2 4.72882 2 8.095C2 11.4612 4.72882 14.19 8.095 14.19Z"
      fill="url(#paint1_radial_818_76075)"
    />
    <path
      d="M6.68038 4.11198C6.6237 3.87386 6.3317 3.78598 5.88946 3.89086C5.51242 3.98158 4.7385 4.38414 4.34444 5.24878C4.27074 5.40752 4.44934 5.44438 4.53154 5.33098C4.80936 4.95112 5.65416 4.34728 6.4054 4.25374C6.70874 4.21688 6.68038 4.11198 6.68038 4.11198Z"
      fill="url(#paint2_linear_818_76075)"
    />
    <path
      d="M6.10491 8.07234C6.46971 8.07234 6.76543 7.47962 6.76543 6.74844C6.76543 6.01728 6.46971 5.42456 6.10491 5.42456C5.74011 5.42456 5.44437 6.01728 5.44437 6.74844C5.44437 7.47962 5.74011 8.07234 6.10491 8.07234Z"
      fill="url(#paint3_radial_818_76075)"
    />
    <path
      d="M6.1049 5.87244C6.38838 5.87244 6.63502 6.20128 6.76258 6.6832C6.75408 5.96598 6.46208 5.3905 6.1049 5.3905C5.74486 5.3905 5.4557 5.96598 5.4472 6.6832C5.57476 6.20128 5.8214 5.87244 6.1049 5.87244Z"
      fill="url(#paint4_linear_818_76075)"
    />
    <path
      d="M12.2113 9.12692C12.1659 9.10992 12.1149 9.10708 12.0695 9.12126C10.7825 9.48978 9.44725 9.67406 8.09785 9.67406C6.74845 9.67406 5.41037 9.48696 4.12617 9.12126C4.07799 9.10708 4.02979 9.10992 3.98443 9.12692C3.84553 9.18362 3.62441 9.3934 3.91639 10.1957V10.1985C4.34729 11.2247 5.48975 13.0277 8.09501 13.0277C10.6946 13.0277 11.837 11.2219 12.2793 10.1985C12.5684 9.3934 12.3502 9.18362 12.2113 9.12692Z"
      fill="url(#paint5_radial_818_76075)"
    />
    <path
      d="M8.09504 12.7414C9.17514 12.7414 9.9859 12.4097 10.5926 11.9561C9.92638 11.6556 9.10426 11.46 8.09504 11.46C7.08582 11.46 6.2637 11.6585 5.5975 11.9618C6.20418 12.4097 7.01496 12.7414 8.09504 12.7414Z"
      fill="url(#paint6_radial_818_76075)"
    />
    <path
      d="M12.129 9.10986C12.1205 9.10986 12.112 9.10986 12.1006 9.1127C10.8023 9.4869 9.45571 9.67684 8.09213 9.67684C6.72855 9.67684 5.38197 9.4869 4.08361 9.1127C4.07509 9.10986 4.06659 9.10986 4.05525 9.10986C3.96737 9.11838 3.54781 9.20626 3.90783 10.1985V10.2013C4.08361 10.6152 4.37559 11.1567 4.83201 11.6528C4.40395 11.1453 4.93123 10.8647 5.86957 10.9781C6.80793 11.0915 8.09213 11.0943 8.09213 11.0943C8.09213 11.0943 9.37349 11.0915 10.3147 10.9781C11.253 10.8647 11.7803 11.1453 11.3522 11.6528C11.8087 11.1567 12.1006 10.6152 12.2764 10.2013V10.1985C12.6364 9.20342 12.2169 9.11554 12.129 9.10986Z"
      fill="url(#paint7_linear_818_76075)"
    />
    <path
      d="M11.4061 10.5046C11.7633 10.4281 12.0327 10.1304 12.0667 9.76471L12.1007 9.40753C10.8023 9.77323 9.45573 9.96033 8.09499 9.96033C6.73425 9.96033 5.38767 9.77323 4.08929 9.40753L4.12331 9.76471C4.15733 10.1276 4.42665 10.4253 4.78385 10.5046C5.86677 10.7399 6.97805 10.8562 8.09499 10.8562C9.21193 10.8562 10.3232 10.7371 11.4061 10.5046Z"
      fill="url(#paint8_radial_818_76075)"
    />
    <path
      d="M9.50958 4.11198C9.56628 3.87386 9.85828 3.78598 10.3005 3.89086C10.6776 3.98158 11.4515 4.38414 11.8455 5.24878C11.9192 5.40752 11.7406 5.44438 11.6584 5.33098C11.3806 4.95112 10.5358 4.34728 9.78458 4.25374C9.48124 4.21688 9.50958 4.11198 9.50958 4.11198Z"
      fill="url(#paint9_linear_818_76075)"
    />
    <path
      d="M10.0851 8.07234C10.4499 8.07234 10.7456 7.47962 10.7456 6.74846C10.7456 6.01728 10.4499 5.42456 10.0851 5.42456C9.72028 5.42456 9.42456 6.01728 9.42456 6.74846C9.42456 7.47962 9.72028 8.07234 10.0851 8.07234Z"
      fill="url(#paint10_radial_818_76075)"
    />
    <path
      d="M10.0851 5.87244C9.8016 5.87244 9.55496 6.20128 9.4274 6.6832C9.4359 5.96598 9.7279 5.3905 10.0851 5.3905C10.4451 5.3905 10.7343 5.96598 10.7428 6.6832C10.6152 6.20128 10.3686 5.87244 10.0851 5.87244Z"
      fill="url(#paint11_linear_818_76075)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_818_76075"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.8958 5.6249) scale(7.35282)"
      >
        <stop stop-color="#FFDF30" />
        <stop offset="1" stop-color="#FFB82E" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_818_76075"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.8958 5.6249) scale(5.7848)"
      >
        <stop stop-color="#FFE95F" />
        <stop offset="1" stop-color="#FFBB47" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_818_76075"
        x1="5.52506"
        y1="4.80588"
        x2="5.37638"
        y2="4.18108"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#3C2200" />
        <stop offset="1" stop-color="#7A4400" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_818_76075"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.90537 6.78198) rotate(77.7198) scale(1.26361 0.636078)"
      >
        <stop offset="0.00132565" stop-color="#7A4400" />
        <stop offset="1" stop-color="#643800" />
      </radialGradient>
      <linearGradient
        id="paint4_linear_818_76075"
        x1="6.10504"
        y1="5.4173"
        x2="6.10504"
        y2="6.652"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#3C2200" />
        <stop offset="1" stop-color="#512D00" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_818_76075"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.09501 11.0699) scale(3.35762)"
      >
        <stop offset="0.00132565" stop-color="#7A4400" />
        <stop offset="1" stop-color="#643800" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_818_76075"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.1453 12.251) scale(2.78274 0.84122)"
      >
        <stop offset="0.2479" stop-color="#FF0000" />
        <stop offset="1" stop-color="#C10000" />
      </radialGradient>
      <linearGradient
        id="paint7_linear_818_76075"
        x1="12.3731"
        y1="10.3796"
        x2="3.86881"
        y2="10.3796"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#3C2200" />
        <stop offset="0.5" stop-color="#512D00" />
        <stop offset="1" stop-color="#3C2200" />
      </linearGradient>
      <radialGradient
        id="paint8_radial_818_76075"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.09499 10.1332) scale(7.36334)"
      >
        <stop offset="0.00132565" stop-color="white" />
        <stop offset="1" stop-color="#A8BBBD" />
      </radialGradient>
      <linearGradient
        id="paint9_linear_818_76075"
        x1="10.6638"
        y1="4.80396"
        x2="10.8125"
        y2="4.17916"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#3C2200" />
        <stop offset="1" stop-color="#7A4400" />
      </linearGradient>
      <radialGradient
        id="paint10_radial_818_76075"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.2851 6.78158) rotate(102.28) scale(1.26361 0.636078)"
      >
        <stop offset="0.00132565" stop-color="#7A4400" />
        <stop offset="1" stop-color="#643800" />
      </radialGradient>
      <linearGradient
        id="paint11_linear_818_76075"
        x1="10.0849"
        y1="5.41732"
        x2="10.0849"
        y2="6.652"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#3C2200" />
        <stop offset="1" stop-color="#512D00" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'mood'
}
</script>
