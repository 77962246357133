<template>
  <div v-if="isShow" class="page-error">
    <h1>Authenticated Failed</h1>
  </div>
</template>

<script>
export default {
  name: 'Error',
  data() {
    return {
      isShow: false,
      timerId: 0
    }
  },
  created() {
    this.timerId = setTimeout(() => {
      this.isShow = true
    }, 5000)
  },
  beforeDestroy() {
    clearTimeout(this.timerId)
  }
}
</script>
