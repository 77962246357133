export const getCodeByISSO = (_location) => {
  let _code
  const ls = _location.search.substring(1).split('&')
  for (let i = 0; i < ls.length; i++) {
    const code_ = ls[i].toLowerCase()
    const isCode = code_.includes('code=')
    if (isCode) {
      _code = code_.substring(5)
      break
    }
  }
  return _code
}
