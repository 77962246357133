import { consoleLog } from '@/comp/utils'

export const checkUser = ({ username, email, fullname, name, domain, accessToken }) => {
  if (!username) consoleLog.error('error: token - username field is empty')
  if (!email) consoleLog.error('error: token - email field is empty')
  if (!fullname) consoleLog.error('error: token - fullname field is empty')
  if (!name) consoleLog.error('error: token - name field is empty')
  if (!domain) consoleLog.error('error: token - domain field is empty')
  if (!accessToken) consoleLog.error('error: token - accessToken field is empty')
}
