import Vue from 'vue'
import App from './App.vue'
import Error from './Error'
import { router } from '@/plugins'
import { store } from '@/store'
import { MUTATION_APP, ACTION_AXIOS_APP } from '@/store/const/type'

import { authentication, checkUser, hash256 } from '@/utils'

import './scss/main.scss'
import './scss/style.scss'
import './scss/watch.scss'
import './scss/media.scss'
import './scss/error.scss'

/** Показывать предупреждение о запуске вью в дев режиме */
Vue.config.productionTip = false

/** Возможность использовать вкладку perfomance во вью дев тулзах */
Vue.config.performance = !__PRODUCTION_STAND_TYPE__

/** Возможность использования девтулзов в продакшн сборке */
Vue.config.devtools = !__PRODUCTION_STAND_TYPE__

authentication()
  .then(({ username, email, fullname, name, domain, accessToken }) => {
    const app = new Vue({ router, store, render: (h) => h(App, { props: { name } }) })
    checkUser({ username, email, fullname, name, domain, accessToken })
    hash256(email).then((avatar) =>
      store.commit(MUTATION_APP.USER, { username, email, fullname, avatar, domain, accessToken })
    )
    store.dispatch(ACTION_AXIOS_APP.ADMIN_POST, username).then(() => {})
    router.onReady(() => app.$mount('#app'))
  })
  .catch(() => {
    const err = new Vue({ router, store, render: (h) => h(Error) })
    router.onReady(() => err.$mount('#app'))
    console.log('Authenticated Failed')
  })
