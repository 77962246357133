export const MUTATION_LIVE = {
  UPDATE: 'MUTATION_LIVE_UPDATE'
}

export const MUTATION_BROADCAST = {
  UPDATE: 'MUTATION_BROADCAST_UPDATE',
  DEL_BY_ID: 'MUTATION_BROADCAST_DEL_BY_ID',
  ADD: 'MUTATION_BROADCAST_ADD',
  CHANGE: 'MUTATION_BROADCAST_CHANGE',
  ADD_IMAGE: 'MUTATION_BROADCAST_ADD_IMAGE',
  DEL_IMAGE: 'MUTATION_BROADCAST_DEL_IMAGE'
}

export const MUTATION_APP = {
  USER: 'MUTATION_APP_USER',
  IS_ADMIN: 'MUTATION_APP_IS_ADMIN',
  IS_ANON: 'MUTATION_APP_IS_ANON'
}

export const MUTATION_STREAM_CTRL = {
  OPEN: 'MUTATION_STREAM_CTRL_OPEN',
  CLOSE: 'MUTATION_STREAM_CTRL_CLOSE'
}

export const MUTATION_WATCH_CARD_DEL = {
  OPEN: 'MUTATION_WATCH_CARD_DEL_OPEN',
  CLOSE: 'MUTATION_WATCH_CARD_DEL_CLOSE'
}

export const MUTATION_STREAM_CHAT_CLEAR = {
  OPEN: 'MUTATION_STREAM_CHAT_CLEAR_OPEN',
  CLOSE: 'MUTATION_STREAM_CHAT_CLEAR_CLOSE'
}
