export const GETTER_BROADCAST = {
  FUTURE: 'GETTER_BROADCAST_FUTURE',
  PAST: 'GETTER_BROADCAST_PAST'
}

export const GETTER_LIVE = {
  ALL: 'GETTER_LIVE_ALL'
}

export const GETTER_APP = {
  USER: 'GETTER_APP_USER',
  IS_ADMIN: 'GETTER_APP_IS_ADMIN',
  IS_ANON: 'GETTER_APP_IS_ANON'
}

export const GETTER_STREAM_CTRL = {
  IS_VISIBLE: 'GETTER_STREAM_CTRL_IS_VISIBLE',
  INFO: 'GETTER_STREAM_CTRL_INFO'
}

export const GETTER_WATCH_CARD_DEL = {
  IS_VISIBLE: 'GETTER_WATCH_CARD_DEL_IS_VISIBLE',
  INFO: 'GETTER_WATCH_CARD_DEL_INFO'
}

export const GETTER_STREAM_CHAT_CLEAR = {
  IS_VISIBLE: 'GETTER_STREAM_CHAT_CLEAR_IS_VISIBLE',
  CHANNEL: 'GETTER_STREAM_CHAT_CLEAR_CHANNEL'
}
