import axios from 'axios'
import { API_URL } from '@/const'

export const getAxiosCallback = async (code) => {
  try {
    const { data } = await axios.get(`${API_URL.AUTH_V1_CALLBACK}?code=${code}`)
    return data
  } catch (e) {
    console.log(e.response?.data)
  }
  return {}
}
