import {
  LS,
  getAxiosISSO,
  getCodeByISSO,
  getAxiosCallback,
  parseToken,
  getIsExpiredToken
} from '@/utils'

export const authentication = async () => {
  let participant, accessToken
  const jwt = localStorage.getItem(LS.JWT)
  if (jwt) {
    const { access_token } = JSON.parse(jwt)
    if (access_token) {
      participant = parseToken(access_token)
      accessToken = access_token
      const isExpiredToken = getIsExpiredToken(participant.exp)
      if (isExpiredToken) {
        localStorage.removeItem(LS.JWT)
        window.location.replace(window.location.href)
      }
    } else {
      localStorage.removeItem(LS.JWT)
      window.location.replace(window.location.href)
    }
  } else {
    const temp = localStorage.getItem(LS.TEMP)
    if (temp) {
      const url = localStorage.getItem(LS.TEMP)
      localStorage.removeItem(LS.TEMP)
      const code = getCodeByISSO(window.location)
      if (code) {
        window.history.pushState('isso', 'Video Platform', url)
        const JWT = await getAxiosCallback(code)
        localStorage.setItem(LS.JWT, JSON.stringify(JWT))
        participant = parseToken(JWT.access_token)
        if (url) window.location.replace(url)
      }
    } else {
      const url = await getAxiosISSO()
      console.log(url)
      localStorage.setItem(LS.TEMP, window.location.href)
      window.location.replace(url)
    }
  }
  if (!accessToken) throw new Error('Authenticated Failed')
  return { ...participant, accessToken }
}
