<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7543 2.47626C9.61146 2.47626 8.60193 3.05149 8 3.92768C7.39813 3.05149 6.38859 2.47626 5.24574 2.47626C3.40192 2.47626 1.90479 3.9734 1.90479 5.8172C1.90479 9.88962 8 13.5506 8 13.5506C8 13.5506 14.0953 9.88962 14.0953 5.8172C14.0953 3.9734 12.5981 2.47626 10.7543 2.47626Z"
      fill="url(#paint0_radial_818_75991)"
    />
    <path
      opacity="0.5"
      d="M12.1371 2.77344C13.9657 5.68772 12.2819 9.51246 10.259 10.5982C8.0266 11.7982 6.49898 11.2344 3.33708 9.44013C5.13898 11.8287 7.99994 13.5468 7.99994 13.5468C7.99994 13.5468 14.0952 9.8858 14.0952 5.81344C14.0952 4.46486 13.2913 3.29915 12.1371 2.77344Z"
      fill="url(#paint1_radial_818_75991)"
    />
    <path
      opacity="0.5"
      d="M10.7543 2.47626C9.61146 2.47626 8.60193 3.05149 8 3.92768C7.39813 3.05149 6.38859 2.47626 5.24574 2.47626C3.40192 2.47626 1.90479 3.9734 1.90479 5.8172C1.90479 9.88962 8 13.5506 8 13.5506C8 13.5506 14.0953 9.88962 14.0953 5.8172C14.0953 3.9734 12.5981 2.47626 10.7543 2.47626Z"
      fill="url(#paint2_radial_818_75991)"
    />
    <path
      opacity="0.5"
      d="M10.7543 2.47626C9.61146 2.47626 8.60193 3.05149 8 3.92768C7.39813 3.05149 6.38859 2.47626 5.24574 2.47626C3.40192 2.47626 1.90479 3.9734 1.90479 5.8172C1.90479 9.88962 8 13.5506 8 13.5506C8 13.5506 14.0953 9.88962 14.0953 5.8172C14.0953 3.9734 12.5981 2.47626 10.7543 2.47626Z"
      fill="url(#paint3_radial_818_75991)"
    />
    <path
      opacity="0.24"
      d="M7.16573 3.83257C7.50093 4.62115 6.75806 5.69162 5.50476 6.22115C4.25142 6.75069 2.96762 6.54495 2.63238 5.76019C2.29714 4.97543 3.04 3.90115 4.29333 3.37162C5.54666 2.8421 6.83046 3.044 7.16573 3.83257Z"
      fill="url(#paint4_radial_818_75991)"
    />
    <path
      opacity="0.24"
      d="M11.2495 3.19254C11.7257 3.71826 11.4323 4.75826 10.5981 5.51254C9.76373 6.26683 8.70087 6.4535 8.22473 5.92778C7.74853 5.40206 8.04187 4.36206 8.87613 3.60778C9.7104 2.8535 10.7733 2.66683 11.2495 3.19254Z"
      fill="url(#paint5_radial_818_75991)"
    />
    <path
      opacity="0.32"
      d="M10.8153 3.36387C12.4915 3.9353 14.0915 6.41149 11.3867 9.49722C9.76767 11.3448 8.00007 12.2782 5.75244 11.9124C6.96767 12.9258 8.00387 13.5506 8.00387 13.5506C8.00387 13.5506 14.0991 9.88962 14.0991 5.8172C14.0953 3.9734 12.5981 2.47626 10.7543 2.47626C9.61147 2.47626 8.60193 3.05149 8.00007 3.92768C8.00007 3.92768 9.5886 2.94482 10.8153 3.36387Z"
      fill="url(#paint6_linear_818_75991)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_818_75991"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.25653 5.56514) rotate(-29.408) scale(9.5376 7.56573)"
      >
        <stop offset="0.2479" stop-color="#FF0000" />
        <stop offset="0.8639" stop-color="#C20000" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_818_75991"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.49234 4.98012) rotate(-29.408) scale(8.21153 6.51385)"
      >
        <stop offset="0.2479" stop-color="#FF0000" />
        <stop offset="1" stop-color="#C20000" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_818_75991"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.25653 5.56514) rotate(-29.408) scale(9.5376 7.56573)"
      >
        <stop stop-color="white" stop-opacity="0.25" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint3_radial_818_75991"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.68513 8.80295) rotate(-26.296) scale(6.96207 3.44025)"
      >
        <stop stop-color="#BD2719" stop-opacity="0.25" />
        <stop offset="1" stop-color="#BD2719" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_818_75991"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.8983 4.79637) rotate(-21.6908) scale(2.47501 1.53744)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_818_75991"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.7336 4.5641) rotate(-40.7634) scale(2.04917 1.273)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint6_linear_818_75991"
        x1="9.25787"
        y1="17.8999"
        x2="10.4389"
        y2="1.97607"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#860805" />
        <stop offset="1" stop-color="#BD2719" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'favorite'
}
</script>
