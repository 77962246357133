export const ACTION_AXIOS_BROADCAST = {
  GET: 'ACTION_AXIOS_BROADCAST_GET',
  POST: 'ACTION_AXIOS_BROADCAST_POST',
  POST_GET_ARCH: 'ACTION_AXIOS_BROADCAST_POST_GET_ARCH',
  PUT: 'ACTION_AXIOS_BROADCAST_PUT',
  DEL_ID: 'ACTION_AXIOS_BROADCAST_DEL_ID',
  PATCH: 'ACTION_AXIOS_BROADCAST_PATCH'
}

export const ACTION_AXIOS_IMAGE = {
  POST: 'ACTION_AXIOS_IMAGE_POST',
  PUT: 'ACTION_AXIOS_IMAGE_PUT'
}

export const ACTION_AXIOS_LIVE = {
  GET: 'ACTION_AXIOS_LIVE_GET'
}

export const ACTION_AXIOS_APP = {
  ADMIN_POST: 'ACTION_AXIOS_APP_ADMIN_POST'
}
