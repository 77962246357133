import axios from 'axios'
import { API_URL } from '@/const'

export const getAxiosISSO = async () => {
  try {
    const { data } = await axios.get(API_URL.AUTH_V1_ISSO)
    return data.url
  } catch (e) {
    console.log(e.response?.data)
  }
  return ''
}
