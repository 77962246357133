<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.95854 14.4C7.46302 14.4 7.27462 14.0033 7.31298 13.235C7.33844 12.7284 7.50904 11.3163 6.79414 10.6007C6.1274 9.93365 5.93348 9.99289 5.17378 9.13181C4.41408 8.27073 3.7814 8.44785 2.89528 8.52399C1.7821 8.61945 2.16134 2.43927 2.9459 2.54641C3.5028 2.62255 4.76876 2.77449 4.97128 2.77449C5.17378 2.77449 6.56248 1.6976 7.7812 1.68471C9.75564 1.66414 10.8446 2.01411 10.8446 2.01411L11.4232 3.32707L11.6168 7.33135L10.6927 9.25797C10.6927 9.25797 9.45218 9.05537 9.0978 9.61255C8.7434 10.1697 9.3764 10.8537 9.3764 12.4494C9.3764 14.0451 8.21168 14.4 7.95854 14.4Z"
      fill="url(#paint0_linear_818_76024)"
    />
    <path
      d="M10.9136 2.0261L10.8384 2.30022C10.616 2.32386 10.3297 2.22256 9.93882 2.22564C8.07178 2.24006 6.28696 2.77636 5.52112 3.38296C5.01946 3.7802 4.79946 4.30882 4.44506 4.15686C4.00784 3.9693 2.76734 3.67766 2.31108 4.2554C2.43872 3.26692 2.6664 2.50806 2.94592 2.54612C3.5028 2.62226 4.76878 2.77422 4.97128 2.77422C5.17378 2.77422 6.52382 1.72618 7.78152 1.68504C9.40862 1.63193 10.8538 1.91221 10.8538 1.91221L10.9136 2.0261Z"
      fill="url(#paint1_linear_818_76024)"
    />
    <path
      d="M3.68596 8.13352C4.4202 7.9309 4.88658 8.26611 5.26182 8.72229C5.63462 9.17541 5.95802 9.57725 6.89476 10.1851C7.8315 10.7929 7.71124 12.4647 7.63514 13.0219C7.55904 13.5791 7.60968 14.4 7.95854 14.4C7.463 14.4 7.27462 14.0033 7.31296 13.235C7.33844 12.7284 7.50904 11.3163 6.79412 10.6007C6.12738 9.93365 5.93348 9.99289 5.17376 9.13181C4.41436 8.27073 3.78138 8.44785 2.89526 8.52399C2.89526 8.33119 2.95172 8.33612 3.68596 8.13352Z"
      fill="url(#paint2_linear_818_76024)"
    />
    <path
      d="M9.09777 9.61286C8.74337 10.17 9.37635 10.854 9.37635 12.4497C9.37635 14.0454 8.21195 14.4 7.95851 14.4C7.89193 14.4 7.83271 14.3926 7.77963 14.3785C8.81241 14.3137 9.43435 13.0106 9.07843 11.6669C8.78971 10.5765 8.64581 9.7025 9.11739 9.18954C9.42239 8.85768 10.029 8.91418 10.693 9.2583C10.6927 9.2583 9.45215 9.05538 9.09777 9.61286Z"
      fill="url(#paint3_linear_818_76024)"
    />
    <path
      d="M9.16744 9.04407C8.55992 8.84145 7.02548 8.36471 5.86322 7.31023C2.91768 4.63793 7.94658 4.30301 8.98518 2.01783C9.1837 1.581 10.3441 4.30885 10.3441 4.30885L10.7194 4.94063L9.63504 7.82933L9.16744 9.04407Z"
      fill="url(#paint4_linear_818_76024)"
    />
    <path
      d="M9.25643 8.81288C8.64891 8.61026 6.16699 7.01948 6.16699 6.23392C6.16699 3.96684 7.88583 5.0858 8.81491 2.41074C8.94347 2.04022 9.39787 1.72864 9.39787 1.72864C9.77435 2.03592 10.3441 4.30882 10.3441 4.30882L10.7194 4.9406L9.63505 7.8293L9.25643 8.81288Z"
      fill="url(#paint5_linear_818_76024)"
    />
    <path
      d="M10.6589 1.8644L11.8218 3.19455L12.078 8.75095L9.16715 9.04443C8.49889 8.68465 8.42157 8.19255 8.66979 7.40237C8.77411 7.06991 8.34977 6.97475 8.32245 6.00621C8.30343 5.33515 8.74281 5.33177 8.74281 5.13223C8.74281 4.93269 8.52405 4.95051 8.45133 4.37245C8.34761 3.54543 9.03981 3.52947 9.03981 3.35265C9.03981 3.17583 8.65537 3.07085 8.67899 2.39671C8.70905 1.54176 7.12215 1.71459 6.04917 2.49249C5.60857 2.81205 5.22411 2.84491 4.92403 2.77245C4.94275 2.77337 4.95871 2.77399 4.97067 2.77399C5.17317 2.77399 6.53335 1.68481 7.78091 1.68481C9.23957 1.68481 10.2487 1.77046 10.6589 1.8644Z"
      fill="url(#paint6_linear_818_76024)"
    />
    <path
      d="M10.9262 8.67784C10.0275 8.67784 9.53382 7.79926 9.87562 7.1242C10.2174 6.44914 9.24264 5.55552 9.3439 5.1156C9.44514 4.676 9.6225 3.56228 9.76148 3.37808C9.90048 3.1939 9.53352 2.77608 9.53352 2.77608L10.6728 2.63672L11.7359 3.10578L11.6979 6.32206L11.8117 8.33556L10.9262 8.67784Z"
      fill="url(#paint7_linear_818_76024)"
    />
    <path
      d="M5.1738 9.13158C4.68042 8.57226 4.24042 8.451 3.7498 8.45562C3.89126 8.22722 3.98974 7.97764 3.97962 7.73358C3.95292 7.09384 4.09376 6.3052 4.20146 7.12546C4.28338 7.75016 4.7068 8.52222 5.21614 9.17916C5.20202 9.16352 5.18822 9.14816 5.1738 9.13158Z"
      fill="url(#paint8_linear_818_76024)"
    />
    <path
      d="M11.607 9.28346C10.5445 9.32398 8.63417 9.38416 8.59151 8.27042C8.56083 7.46274 9.13337 7.19782 10.0189 7.16406C10.9044 7.13028 11.3471 7.1134 11.7138 7.09958C12.0808 7.08546 13.2341 7.09252 13.2455 8.06782C13.2596 9.28622 11.607 9.28346 11.607 9.28346Z"
      fill="url(#paint9_linear_818_76024)"
    />
    <path
      d="M9.85933 5.2571C9.57461 5.26538 8.72867 5.05202 8.64951 4.5145C8.58723 4.09178 8.80293 3.54566 9.53901 3.41672C10.4714 3.25342 12.0252 3.16776 12.6057 3.38512C12.865 3.48212 13.0911 3.7538 13.1031 4.16546C13.1151 4.57712 12.8095 5.13184 12.1452 5.15118C11.4809 5.17022 11.1348 5.16008 10.7562 5.2049C10.3009 5.25862 10.0873 5.25064 9.85933 5.2571Z"
      fill="url(#paint10_linear_818_76024)"
    />
    <path
      d="M9.56295 3.37871C9.24017 3.37871 8.84129 3.09383 8.84129 2.61893C8.84129 2.14403 8.96465 1.79253 9.65777 1.6596C10.3509 1.52668 11.4616 1.64057 11.8792 1.77349C12.2047 1.87725 12.3253 2.18179 12.3253 2.68523C12.3253 3.18869 12.0121 3.27649 11.6703 3.27649C11.3284 3.27649 10.9136 3.21417 10.6767 3.22645C10.0155 3.26175 9.88573 3.37871 9.56295 3.37871Z"
      fill="url(#paint11_linear_818_76024)"
    />
    <path
      d="M11.5165 9.05841C10.5887 9.09371 8.92047 9.14745 8.88703 8.26701C8.86279 7.62849 9.36353 7.41729 10.1367 7.38781C10.9099 7.35835 12.9442 7.32119 12.951 8.09203C12.9595 9.05535 11.5165 9.05841 11.5165 9.05841Z"
      fill="url(#paint12_linear_818_76024)"
    />
    <path
      d="M9.94492 5.03027C9.69702 5.03733 9.00298 4.97839 8.93638 4.46481C8.89128 4.11729 9.0714 3.67095 9.71236 3.56443C10.5245 3.42935 11.877 3.35691 12.3824 3.53403C12.6079 3.61323 12.8046 3.83519 12.8141 4.17255C12.8239 4.50993 12.5131 4.92343 11.935 4.94033C11.3566 4.95689 11.0492 4.90349 10.7194 4.94093C10.3232 4.98513 10.1431 5.02443 9.94492 5.03027Z"
      fill="url(#paint13_linear_818_76024)"
    />
    <path
      d="M9.7007 3.20549C9.42148 3.20549 9.07661 2.97619 9.07661 2.59369C9.07661 2.21119 9.18337 1.92814 9.78291 1.82131C10.3825 1.71448 11.3431 1.80596 11.7046 1.9131C11.9859 1.9966 12.0906 2.24189 12.0906 2.64741C12.0906 3.05263 11.8196 3.12353 11.5239 3.12353C11.2281 3.12353 10.8694 3.07319 10.6644 3.08333C10.0922 3.11125 9.97992 3.20549 9.7007 3.20549Z"
      fill="url(#paint14_linear_818_76024)"
    />
    <path
      d="M9.53071 8.26681C9.55373 8.87341 10.0373 9.16411 10.6482 9.29795C9.67309 9.27525 8.62313 9.09289 8.59151 8.27049C8.56083 7.46281 9.13337 7.19789 10.0189 7.16411C10.1164 7.16043 10.2082 7.15675 10.2953 7.15369C9.79797 7.29919 9.50647 7.62705 9.53071 8.26681Z"
      fill="url(#paint15_linear_818_76024)"
    />
    <path
      d="M9.61384 9.18795C9.04652 9.06393 8.61144 8.79563 8.5915 8.27039C8.56604 7.59901 8.95786 7.30277 9.59942 7.20239C8.29786 7.72241 8.76486 9.00253 9.61384 9.18795Z"
      fill="url(#paint16_linear_818_76024)"
    />
    <path
      d="M7.1292 3.15002C7.02826 2.78778 6.32378 2.66774 5.5561 2.88202C4.78842 3.0963 4.2481 3.56352 4.34904 3.92576C4.44998 4.288 5.15446 4.40804 5.92214 4.19376C6.68982 3.97948 7.23016 3.51256 7.1292 3.15002Z"
      fill="url(#paint17_radial_818_76024)"
    />
    <path
      d="M12.4029 8.10379C12.3956 7.63779 11.7727 7.41675 11.0913 7.37531C10.5341 7.34155 9.91549 7.73601 9.93145 8.19127C9.94739 8.64653 10.0284 9.04991 11.1864 9.00969C11.8688 8.98605 12.41 8.55935 12.4029 8.10379Z"
      fill="url(#paint18_radial_818_76024)"
    />
    <path
      d="M12.5269 4.00734C12.5232 3.36206 11.8138 3.48576 11.1323 3.44094C10.2683 3.38384 9.69548 3.65308 9.69516 4.10864C9.69456 4.5642 10.0232 4.89604 11.1821 4.89788C11.8644 4.89912 12.5318 4.8365 12.5269 4.00734Z"
      fill="url(#paint19_radial_818_76024)"
    />
    <path
      d="M11.9022 2.3807C11.8988 1.83058 11.2545 1.75568 10.6439 1.75537C10.0333 1.75476 9.37057 2.07862 9.37025 2.46696C9.36995 2.8553 9.66359 3.13834 10.6997 3.13986C11.31 3.14078 11.9065 3.08738 11.9022 2.3807Z"
      fill="url(#paint20_radial_818_76024)"
    />
    <path
      d="M9.44177 4.38403C9.53873 5.11865 10.102 5.07567 10.7479 5.15181C10.0045 5.20737 9.69245 5.21535 9.69245 5.21535C9.62309 5.22211 9.54547 5.22241 9.46477 5.21413C9.22331 5.16101 8.94961 5.05111 8.78699 4.84483C8.73177 4.76623 8.68697 4.67353 8.65751 4.56363C8.65445 4.54767 8.65137 4.53139 8.64953 4.51481C8.59707 4.09057 8.80295 3.54597 9.53903 3.41705C9.64519 3.39831 9.75995 3.38081 9.87961 3.36455C9.93699 3.35717 9.99957 3.34951 10.0668 3.34091C10.0849 3.33877 10.103 3.33661 10.1214 3.33447C9.71545 3.52633 9.37057 3.84405 9.44177 4.38403Z"
      fill="url(#paint21_linear_818_76024)"
    />
    <path
      d="M9.62186 5.22001C9.57184 5.22153 9.51876 5.21969 9.46446 5.21417C9.22084 5.16045 8.94468 5.04901 8.783 4.83935C8.7296 4.76199 8.68664 4.67051 8.6578 4.56337C8.65474 4.54741 8.65258 4.53145 8.64982 4.51487C8.57526 4.09705 8.78208 3.60465 9.42518 3.44165C8.32796 4.04703 8.79588 5.21939 9.62186 5.22001Z"
      fill="url(#paint22_linear_818_76024)"
    />
    <path
      d="M10.2604 3.26667C9.93607 3.31425 9.80289 3.37903 9.56295 3.37903C9.24017 3.37903 8.84129 3.09415 8.84129 2.61925C8.84129 2.44671 8.85787 2.29077 8.91157 2.15539C8.96617 2.04365 9.04565 1.93897 9.15457 1.85056C9.27423 1.76706 9.43685 1.70229 9.65747 1.65992C9.80565 1.63137 9.97319 1.61449 10.1487 1.6062C9.80995 1.80298 9.23619 1.90889 9.35647 2.56767C9.48963 3.29523 9.92379 3.19699 10.2604 3.26667Z"
      fill="url(#paint23_linear_818_76024)"
    />
    <path
      d="M9.54853 3.37836C9.22851 3.37008 8.84129 3.08674 8.84129 2.6189C8.84129 2.1839 8.94531 1.85235 9.49577 1.69763C8.63633 2.2502 8.91217 3.18252 9.54853 3.37836Z"
      fill="url(#paint24_linear_818_76024)"
    />
    <path
      d="M13.2455 8.06795C13.2467 8.17723 13.2344 8.27639 13.2114 8.36695C13.297 7.98629 12.958 7.60809 12.0593 7.42451C11.4637 7.30295 9.88206 7.15099 8.7876 7.58569C9.02386 7.30019 9.45862 7.18599 10.0189 7.16451C10.9044 7.13073 11.3472 7.11385 11.7138 7.10003C12.0808 7.08561 13.2341 7.09267 13.2455 8.06795Z"
      fill="url(#paint25_linear_818_76024)"
    />
    <path
      d="M13.1031 4.19165C13.1019 4.60055 12.7954 5.13255 12.1449 5.15129C11.4806 5.17031 11.1345 5.16019 10.7559 5.20501C10.3009 5.25873 10.087 5.25075 9.85934 5.25719C9.6872 5.26211 9.26256 5.21023 8.97382 5.03063C9.29846 4.91797 9.82742 4.74851 10.5718 4.79611C12.3554 4.91091 12.8629 4.69511 13.1031 4.19165Z"
      fill="url(#paint26_linear_818_76024)"
    />
    <path
      d="M9.47703 7.09988C9.09747 7.10756 8.49457 6.88406 8.47891 6.09542C8.46327 5.30678 9.15271 5.11922 9.45647 5.11308C9.76023 5.10694 10.8222 5.0345 12.132 5.0084C13.4419 4.9823 13.6174 5.6856 13.6247 6.05398C13.6321 6.42268 13.3986 7.08914 12.6107 7.1051C10.3325 7.15084 9.47703 7.09988 9.47703 7.09988Z"
      fill="url(#paint27_linear_818_76024)"
    />
    <path
      d="M9.68079 6.87114C9.35065 6.87758 8.82719 6.70628 8.81523 6.09814C8.80325 5.49002 9.40281 5.34358 9.66667 5.33836C9.93085 5.33314 10.8541 5.27512 11.9927 5.2524C13.1314 5.2297 13.2826 5.77182 13.2881 6.0564C13.2937 6.34066 13.2547 6.85516 12.5696 6.86898C10.5893 6.90828 9.68079 6.87114 9.68079 6.87114Z"
      fill="url(#paint28_linear_818_76024)"
    />
    <path
      d="M9.29355 6.17161C9.33159 6.98113 9.89645 6.98329 10.5341 7.12327C9.78845 7.11867 9.47703 7.10025 9.47703 7.10025C9.09747 7.10791 8.49457 6.88443 8.47891 6.09579C8.46327 5.30715 9.15271 5.11959 9.45647 5.11345C9.56601 5.11131 9.77433 5.10055 10.0566 5.08643C9.63565 5.25989 9.26561 5.57607 9.29355 6.17161Z"
      fill="url(#paint29_linear_818_76024)"
    />
    <path
      d="M12.9574 6.08782C12.9838 5.44316 12.268 5.3219 11.5859 5.28936C10.9038 5.25682 10.1456 5.60156 10.1238 6.0565C10.1021 6.51146 10.4147 6.85834 11.5721 6.91452C12.2545 6.94738 12.9237 6.91606 12.9574 6.08782Z"
      fill="url(#paint30_radial_818_76024)"
    />
    <path
      d="M9.40582 7.09877C9.02658 7.07851 8.49332 6.83661 8.47858 6.09585C8.46356 5.34621 9.0858 5.13961 9.40828 5.11597C8.1604 5.61973 8.5694 7.02449 9.40582 7.09877Z"
      fill="url(#paint31_linear_818_76024)"
    />
    <path
      d="M13.6226 6.13384C13.4581 4.9673 10.3754 5.18096 8.70966 5.43146C8.93458 5.19048 9.26902 5.11742 9.45648 5.11344C9.76024 5.1073 10.8222 5.03484 12.132 5.00876C13.4419 4.98266 13.6174 5.68596 13.6247 6.05434C13.625 6.0795 13.6244 6.10622 13.6226 6.13384Z"
      fill="url(#paint32_linear_818_76024)"
    />
    <path
      d="M12.0783 3.53614C11.4812 3.50974 9.9014 3.31328 8.74956 3.93062C8.87812 3.69178 9.12696 3.48888 9.53902 3.41672C10.4715 3.25342 12.0253 3.16776 12.6058 3.38512C12.865 3.48212 13.0912 3.7538 13.1031 4.16546C13.0648 3.80752 12.7239 3.5647 12.0783 3.53614Z"
      fill="url(#paint33_linear_818_76024)"
    />
    <path
      d="M12.2796 2.23974C11.8884 1.94197 11.2121 1.76607 10.2364 1.93092C9.6338 2.03284 9.2208 2.06752 8.94926 2.0749C9.05788 1.87842 9.26744 1.73414 9.65774 1.65924C10.3509 1.52631 11.4616 1.6402 11.8792 1.77313C12.0936 1.84189 12.2188 1.99784 12.2796 2.23974Z"
      fill="url(#paint34_linear_818_76024)"
    />
    <path
      d="M8.96954 3.04622C9.69242 3.30962 10.0468 2.91882 10.8203 2.91882C11.6236 2.91882 12.0065 3.2433 12.2919 2.9348C12.2023 3.21936 11.9458 3.27676 11.6705 3.27676C11.3287 3.27676 10.9139 3.21446 10.6767 3.22674C10.0152 3.26142 9.88572 3.37868 9.56294 3.37868C9.35398 3.37868 9.11344 3.25928 8.96954 3.04622Z"
      fill="url(#paint35_linear_818_76024)"
    />
    <path
      d="M2.9459 2.54605C3.5028 2.62219 4.76876 2.77415 4.97126 2.77415C3.13522 4.08711 3.95168 6.87973 4.62916 8.29215C4.72428 8.49047 4.78288 8.65317 4.81448 8.79131C4.20358 8.32531 3.63842 8.45977 2.89528 8.52364C1.7821 8.6191 2.16134 2.43891 2.9459 2.54605Z"
      fill="url(#paint36_linear_818_76024)"
    />
    <path
      d="M4.97125 2.77385C4.81383 2.90799 4.62391 3.13393 4.45301 3.35221C4.45301 3.35221 3.53129 2.88037 2.54301 3.10231C2.65685 2.74131 2.79369 2.52519 2.94587 2.54607C3.50277 2.62189 4.76873 2.77385 4.97125 2.77385Z"
      fill="url(#paint37_linear_818_76024)"
    />
    <path
      d="M8.54917 12.0772C8.18833 11.9992 7.89594 12.4977 7.73086 13.0794C7.59586 13.5553 7.77934 14.1609 8.13374 14.2294C8.48812 14.2979 8.81519 14.3009 9.01064 13.2925C9.1254 12.6982 8.90171 12.1533 8.54917 12.0772Z"
      fill="url(#paint38_radial_818_76024)"
    />
    <path
      d="M10.6927 9.25845C10.6927 9.25845 9.45216 9.05585 9.09778 9.61301C8.7434 10.1702 9.37638 10.8541 9.37638 12.4498C9.37638 14.0455 8.21166 14.4001 7.95852 14.4001C7.463 14.4001 7.27462 14.0035 7.31296 13.2351C7.33844 12.7286 7.50902 11.3165 6.79412 10.6009C6.12738 9.93381 5.93346 9.99307 5.17376 9.13197C4.41406 8.27089 9.88204 8.89191 10.6927 9.25845Z"
      fill="url(#paint39_linear_818_76024)"
    />
    <path
      d="M9.5485 3.37836C9.34816 3.38236 9.14748 3.28934 9.0085 3.1383C8.8652 2.9885 8.80046 2.77238 8.80906 2.56916C8.8155 2.37054 8.84556 2.15442 8.97566 1.98804C9.10392 1.82012 9.30428 1.74092 9.49604 1.69763C9.31042 1.76455 9.12662 1.85941 9.01678 2.01844C8.90602 2.17622 8.87686 2.37576 8.87564 2.57038C8.86306 2.7641 8.92166 2.95688 9.04684 3.10454C9.1702 3.25098 9.35 3.3575 9.5485 3.37836Z"
      fill="#804B24"
    />
    <path
      d="M9.23003 5.14504C9.09535 5.10146 8.96065 5.04158 8.85141 4.94396C8.73697 4.84972 8.66763 4.71004 8.62865 4.57068L8.62805 4.56852C8.60381 4.44298 8.59183 4.30852 8.61791 4.17988C8.64369 4.05126 8.69707 3.92848 8.77409 3.82286C8.92997 3.6089 9.17819 3.48888 9.42457 3.44128C9.18493 3.51926 8.96003 3.6528 8.82103 3.85632C8.67897 4.05772 8.63847 4.31526 8.68635 4.55686L8.68573 4.55472C8.72285 4.68734 8.77717 4.8181 8.87873 4.91358C8.97875 5.01058 9.09933 5.08826 9.23003 5.14504Z"
      fill="#804B24"
    />
    <path
      d="M9.40584 7.09871C9.16928 7.09627 8.92566 7.01491 8.74678 6.84669C8.56544 6.67999 8.47156 6.43503 8.45192 6.19495C8.43198 5.95613 8.47156 5.69703 8.62128 5.49965C8.76794 5.30041 8.99838 5.18591 9.23064 5.14355C9.0042 5.20985 8.7925 5.33817 8.66302 5.52943C8.53168 5.71975 8.49456 5.95981 8.51818 6.18943C8.5323 6.41997 8.62006 6.64623 8.78236 6.81047C8.94406 6.97439 9.17112 7.07201 9.40584 7.09871Z"
      fill="#804B24"
    />
    <path
      d="M9.61388 9.18795C9.35768 9.14283 9.0978 9.06515 8.88362 8.90093C8.66548 8.74067 8.54458 8.46009 8.5584 8.19211C8.559 7.92809 8.64982 7.64321 8.8643 7.47131C9.07386 7.29725 9.34172 7.23247 9.59914 7.20239C9.3454 7.25643 9.08828 7.33931 8.89774 7.50999C8.70414 7.67883 8.6213 7.93853 8.62436 8.19303C8.60748 8.44935 8.7158 8.69955 8.91524 8.86163C9.11344 9.02433 9.3629 9.11949 9.61388 9.18795Z"
      fill="#804B24"
    />
    <path
      d="M8.74956 3.93065C8.82534 3.77899 8.94592 3.64883 9.09198 3.55797C9.23896 3.46833 9.40678 3.41645 9.57492 3.38881C9.90936 3.32957 10.2463 3.28689 10.5847 3.25773C10.9234 3.23563 11.2631 3.22611 11.6024 3.23471C11.94 3.24669 12.2864 3.26571 12.6058 3.38575C12.4444 3.33263 12.275 3.31545 12.1072 3.30193C11.9393 3.28537 11.7703 3.28107 11.6015 3.27891C11.2637 3.27769 10.9259 3.29519 10.5893 3.32373C10.2521 3.34523 9.91582 3.38053 9.58198 3.43211C9.2543 3.47661 8.9223 3.63133 8.74956 3.93065Z"
      fill="#804B24"
    />
    <path
      d="M8.70966 5.43112C8.83118 5.2945 8.99532 5.1978 9.17082 5.14254C9.34848 5.08452 9.53688 5.09312 9.71668 5.08084L10.8081 5.02682L11.9001 4.9952C12.2606 4.97524 12.6407 4.9869 12.9786 5.13118C12.6315 5.01208 12.263 5.0173 11.901 5.0394L10.8096 5.09312L9.7176 5.12474C9.53504 5.13304 9.35308 5.12168 9.18004 5.17356C9.00544 5.21746 8.83884 5.30218 8.70966 5.43112Z"
      fill="#804B24"
    />
    <path
      d="M12.6107 7.10549C12.0888 7.13405 11.5663 7.14387 11.0437 7.14971C10.5209 7.15799 9.99868 7.15769 9.47554 7.12607H9.47768C9.20736 7.12545 8.93276 7.02812 8.74252 6.83288C8.6471 6.7368 8.57684 6.61739 8.53572 6.48969C8.49522 6.36167 8.4808 6.22815 8.47896 6.09583C8.49092 6.35983 8.5799 6.62477 8.7686 6.80681C8.95362 6.99037 9.21994 7.07817 9.47646 7.07419H9.4786C9.73848 7.08431 9.9999 7.08585 10.2607 7.08769L11.0437 7.09843L11.8271 7.09996C12.0882 7.0972 12.3493 7.10457 12.6107 7.10549Z"
      fill="#804B24"
    />
    <defs>
      <linearGradient
        id="paint0_linear_818_76024"
        x1="10.7336"
        y1="8.14747"
        x2="6.92518"
        y2="7.99463"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_818_76024"
        x1="6.89362"
        y1="6.16434"
        x2="6.62752"
        y2="3.30534"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_818_76024"
        x1="6.13466"
        y1="10.4058"
        x2="9.00368"
        y2="6.30381"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_818_76024"
        x1="4.05841"
        y1="12.0401"
        x2="11.4065"
        y2="11.4898"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.3118" stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#FF8900" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_818_76024"
        x1="3.91856"
        y1="5.50697"
        x2="13.5678"
        y2="5.50703"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#FFA754" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_818_76024"
        x1="2.36269"
        y1="5.49242"
        x2="21.4258"
        y2="4.88526"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_818_76024"
        x1="5.39167"
        y1="3.00135"
        x2="8.54425"
        y2="4.70973"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_818_76024"
        x1="9.00308"
        y1="5.42684"
        x2="11.6319"
        y2="5.77208"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#643800" stop-opacity="0" />
        <stop offset="1" stop-color="#643800" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_818_76024"
        x1="4.33744"
        y1="11.2306"
        x2="4.90764"
        y2="1.78922"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#FFA754" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_818_76024"
        x1="13.0655"
        y1="8.19846"
        x2="9.33509"
        y2="8.19844"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint10_linear_818_76024"
        x1="13.0655"
        y1="4.256"
        x2="9.33305"
        y2="4.25598"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint11_linear_818_76024"
        x1="13.0656"
        y1="2.48923"
        x2="9.33515"
        y2="2.48921"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_818_76024"
        x1="8.83223"
        y1="8.17993"
        x2="10.7752"
        y2="8.22423"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_818_76024"
        x1="12.2301"
        y1="4.22961"
        x2="10.2495"
        y2="4.22959"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint14_linear_818_76024"
        x1="12.2301"
        y1="2.48917"
        x2="10.2495"
        y2="2.48915"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint15_linear_818_76024"
        x1="9.81237"
        y1="8.22553"
        x2="8.75559"
        y2="8.22553"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FBA23B" stop-opacity="0" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_818_76024"
        x1="9.62248"
        y1="8.19507"
        x2="8.40716"
        y2="8.19505"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#E89043" />
      </linearGradient>
      <radialGradient
        id="paint17_radial_818_76024"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.60098 3.78638) rotate(-15.5933) scale(1.48359 0.688916)"
      >
        <stop stop-color="#FBE07A" />
        <stop offset="1" stop-color="#FFD748" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_818_76024"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.1691 8.18935) rotate(-2.02716) scale(1.2694 0.83315)"
      >
        <stop stop-color="#FBE07A" />
        <stop offset="1" stop-color="#FFD748" />
      </radialGradient>
      <radialGradient
        id="paint19_radial_818_76024"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.1077 4.09876) rotate(0.0544049) scale(1.36326 0.7349)"
      >
        <stop stop-color="#FBE07A" />
        <stop offset="1" stop-color="#FFD748" stop-opacity="0" />
      </radialGradient>
      <radialGradient
        id="paint20_radial_818_76024"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.6361 2.44656) rotate(0.0539593) scale(1.24818 0.781198)"
      >
        <stop stop-color="#FBE07A" />
        <stop offset="1" stop-color="#FFD748" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint21_linear_818_76024"
        x1="9.93901"
        y1="4.27715"
        x2="8.40737"
        y2="4.27715"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FBA23B" stop-opacity="0" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <linearGradient
        id="paint22_linear_818_76024"
        x1="9.61346"
        y1="4.33075"
        x2="8.78842"
        y2="4.33073"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <linearGradient
        id="paint23_linear_818_76024"
        x1="9.88397"
        y1="2.47919"
        x2="8.09279"
        y2="2.80813"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FBA23B" stop-opacity="0" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_818_76024"
        x1="9.69875"
        y1="2.53768"
        x2="8.94165"
        y2="2.53768"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <linearGradient
        id="paint25_linear_818_76024"
        x1="10.9056"
        y1="8.43347"
        x2="11.3871"
        y2="6.10331"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint26_linear_818_76024"
        x1="11.2714"
        y1="3.82959"
        x2="10.746"
        y2="5.72491"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint27_linear_818_76024"
        x1="13.0655"
        y1="6.06578"
        x2="9.33511"
        y2="6.06576"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FFD748" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_818_76024"
        x1="9.18465"
        y1="6.16984"
        x2="12.0422"
        y2="6.06548"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFCB4B" />
        <stop offset="0.7382" stop-color="#FFD748" />
        <stop offset="1" stop-color="#FFCB4B" />
      </linearGradient>
      <linearGradient
        id="paint29_linear_818_76024"
        x1="10.2997"
        y1="6.10439"
        x2="8.16481"
        y2="6.10439"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FBA23B" stop-opacity="0" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <radialGradient
        id="paint30_radial_818_76024"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(11.5394 6.10028) rotate(2.73604) scale(1.39589 0.91622)"
      >
        <stop stop-color="#FBE07A" />
        <stop offset="1" stop-color="#FFD748" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint31_linear_818_76024"
        x1="9.30734"
        y1="6.10705"
        x2="8.63328"
        y2="6.10705"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00132565" stop-color="#FFBC47" />
        <stop offset="1" stop-color="#FBA23B" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_818_76024"
        x1="11.0826"
        y1="6.31248"
        x2="11.4881"
        y2="3.73532"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint33_linear_818_76024"
        x1="10.8017"
        y1="4.31872"
        x2="11.8405"
        y2="2.06484"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint34_linear_818_76024"
        x1="10.5948"
        y1="2.3414"
        x2="10.7468"
        y2="0.916646"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint35_linear_818_76024"
        x1="10.6306"
        y1="2.55762"
        x2="10.6306"
        y2="3.58822"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_818_76024"
        x1="3.55012"
        y1="6.33871"
        x2="2.21982"
        y2="10.3941"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C86F34" stop-opacity="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <linearGradient
        id="paint37_linear_818_76024"
        x1="3.78163"
        y1="3.87778"
        x2="3.70559"
        y2="0.971276"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
      <radialGradient
        id="paint38_radial_818_76024"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.3754 13.1595) rotate(-79.0714) scale(1.33845 0.785174)"
      >
        <stop stop-color="#FBE07A" />
        <stop offset="1" stop-color="#FFD748" stop-opacity="0" />
      </radialGradient>
      <linearGradient
        id="paint39_linear_818_76024"
        x1="7.84978"
        y1="8.69587"
        x2="8.82542"
        y2="17.5166"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFBC47" stop-opacity="0" />
        <stop offset="1" stop-color="#C86F34" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'thumb-down'
}
</script>
