export const parseToken = (token) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((item) => '%' + ('00' + item.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  )
  const { email, fullname, name, username, domain, exp, iat } = JSON.parse(jsonPayload)
  return { email, fullname, name, username, domain, exp, iat }
}
